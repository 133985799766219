import { createStyles } from '@material-ui/styles';
import { PRIMARY_COLOR, THIRD_COLOR } from 'styles/colors';

const useStyles = createStyles({
  moviePosterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  moviePoster: {
    maxWidth: '100%',
    maxHeight: 390,
  },
  movieOptions: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& >*': {
      marginRight: 15,
      marginLeft: 15,
      marginBottom: 15,
      // width: 'calc(30% - 30px)',
      // maxWidth: 'calc(30% - 30px)',
      height: 'auto',
      boxSizing: 'border-box',
    },
  },
  movieOption: {
    minWidth: 150,
    height: 40,
    paddingRight: 20,
    paddingLeft: 20,
    backgroundColor: '#E5E5E5',
    color: '#7A7A7A',
    border: '2px solid #7A7A7A',
    borderRadius: 10,
    textTransform: 'capitalize',
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: '0.02em',
    '&:hover': {
      //color: 'white',
      color: 'black',
      //backgroundColor: '#7A7A7A',
      backgroundColor: 'rgba(43, 55, 238, 0.12) !important',
      borderRadius: '10px',
    },
    '@media (max-width: 1800px)': {
      minWidth: 140,
    },
    '@media (max-width: 1600px)': {
      minWidth: 120,
      '& .MuiSvgIcon-root': {
        //display: 'none',
        paddingRight: '5px',
      },
    },
  },
  optionDescription: {
    marginLeft: 8,
    '@media (max-width: 1600px)': {
      marginLeft: 0,
    },
  },
  productOptions: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'flex-end',
    '& >*': {
      marginLeft: 24,
    },
  },

  /* Video Popup */
  popupContainer: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 15,
  },
  videoHeader: {
    marginBottom: 30,
  },
  closeButton: {
    position: 'absolute',
    top: 15,
    right: 20,
  },
  videoContainer: {
    '& .video-js': {
      width: '60vw',
      height: '60vh',
    },
  },
  closeButtonFooter: {
    minWidth: 160,
    height: 40,
    paddingRight: 20,
    paddingLeft: 20,
    backgroundColor: '#FFFFFF',
    color: '#7A7A7A',
    border: '2px solid #7A7A7A',
    borderRadius: 10,
    textTransform: 'capitalize',
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: '0.02em',
    '&:hover': {
      color: 'white',
      backgroundColor: '#7A7A7A',
    },
  },
  productNotFoundTitle: {
    fontSize: 50,
    color: 'rgb(43, 55, 238)',
    fontWeight: 700,
  },
  productNotFoundTxt: {
    marginTop: 30,
    fontSize: 35,
    color: '#7A7A7A',
    fontWeight: 500,
  },
  productNotFound: {
    textAlign: 'center',
  },
  licenseError: {
    marginTop: -40,
    fontSize: '1rem',
    paddingBottom: 20,
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '1rem',
      marginRight: 5,
    },
  },
  genresContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  genreItem: {
    color: THIRD_COLOR,
    backgroundColor: PRIMARY_COLOR,
    padding: '10px',
    fontSize: 14,
    fontWeight: 400,
    borderRadius: '10px',
    letterSpacing: '0.02em',
    marginRight: '10px',
    marginBottom: '10px',
  },
  genreFlexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    // gap: '10px',
  },
  buttonGroup: {
    display: 'grid',
    gridGap: 25,
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
  },
  toggleButton: {
    width: 'auto',
    height: 50,
    marginLeft: 0,
    borderRadius: '10px !important',
    borderLeft: 'none',
    border: 'none',
    color: `${THIRD_COLOR} !important`,
    fontWeight: 400,
    backgroundColor: `${PRIMARY_COLOR} !important`,
    textTransform: 'capitalize',
    fontSize: 14,
    paddingLeft: 5,
    paddingRight: 5,
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.5)',
    },
  },
  toggleButtonSelected: {
    color: '#000000',
  },
  description: {
    fontSize: 16,
    color: 'black',
    height: 40,
    display: 'flex',
    alignItems: 'center',
  },
});

export default useStyles;
