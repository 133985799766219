import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { SidebarItem, UserModel } from 'models';
import { RootState } from 'redux/store';
import { isBuyer, isSeller } from 'services/authService';

import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import MovieIcon from '@material-ui/icons/Movie';
import PersonIcon from '@material-ui/icons/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import TheatersIcon from '@mui/icons-material/Theaters';
import SidebarStyles from './SidebarStyles';

import { project } from 'project';

interface OwnProps {
  classes?: any;
  open: boolean;
  handleDrawerClose: (payload: any) => void;
}

interface StateProps {
  user: UserModel;
}

type Props = StateProps & OwnProps;

const isMovieActive = (match: any, location: any) => {
  const pathname: string = location.pathname;
  if (pathname === '/movies' || pathname === '/catalogue') {
    return true;
  } else if (
    pathname.startsWith('/movies') ||
    pathname.startsWith('/catalogue')
  ) {
    return true;
  } else return false;
};

const LeftSideBar: React.FC<Props> = ({ classes, user }) => {
  const [collapsed, setCollapsed] = useState(false);

  const mainListItems: SidebarItem[] = [];

  if (user.role !== 'admin') {
    mainListItems.push({
      name: isSeller() ? 'My Films' : 'Films',
      url: '/catalogue',
      icon: <MovieIcon />,
      isActive: isMovieActive,
    });

    if (isSeller() && project !== 'mfh') {
      mainListItems.push({
        name: 'My Bespoke Licenses',
        url: '/myBespokeLicenses',
        icon: <AssignmentTurnedInIcon />,
      });
    }

    if (project !== 'mfh') {
      mainListItems.push({
        name: 'My Pending Licenses',
        url: '/myPendingLicenses',
        icon: <AssignmentTurnedInIcon />,
      });
    }

    if (isBuyer() && project !== 'scene') {
      mainListItems.push({
        name: 'My Downloads',
        url: '/myDownloads',
        icon: <TheatersIcon />,
      });
    }

    if (user.role !== 'user') {
      mainListItems.push({
        name: 'My Signed Licenses',
        url: '/myCustomLicenses',
        icon: <AssignmentIcon />,
      });
    }
    mainListItems.push({
      name: 'Profile',
      url: '/profile',
      icon: <PersonIcon />,
    });
  }

  if (user.role === 'admin') {
    mainListItems.push({
      name: 'Management',
      url: '/management',
      icon: <GroupsIcon />,
    });
  }

  return (
    <>
      <Drawer
        variant='permanent'
        classes={{
          paper: `${classes.sidebar} ${
            collapsed ? classes.sidebarCollapsed : ''
          }`,
        }}
        open={true}
      >
        <List className={classes.itemsContainer}>
          <div
            className={`${
              collapsed ? classes.toolbarLogo : classes.toolbarLogoCollapsed
            }`}
          >
            <IconButton onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? (
                <MenuIcon
                  style={{
                    color: 'white',
                  }}
                />
              ) : (
                <ChevronLeftIcon style={{ color: 'white' }} />
              )}
            </IconButton>
          </div>
          {mainListItems.map((item: SidebarItem, index: number) => (
            <NavLink
              key={index}
              className={classes.sidebarItemLink}
              activeClassName='active'
              isActive={item.isActive}
              to={item.url}
            >
              <ListItem className={classes.sidebarItem}>
                <ListItemIcon className={classes.sidebarItemIcon}>
                  {item.icon}
                </ListItemIcon>
                {!collapsed && (
                  <ListItemText
                    classes={{ primary: classes.sidebarItemTxt }}
                    primary={item.name}
                  />
                )}
              </ListItem>
            </NavLink>
          ))}
        </List>
        <div className={classes.sidebarFooter}>
          <span className={classes.sidebarFooterTxt}>
            {/* Project supported by EIT */}
          </span>
        </div>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

export default connect<StateProps, Record<string, never>, OwnProps, RootState>(
  mapStateToProps
)(withStyles(SidebarStyles)(LeftSideBar));
