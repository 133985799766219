import {
  InputAdornment,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { NavLink } from 'react-router-dom';
import { Button } from 'rsuite';
import { isBuyer, isSeller } from 'services/authService';

import combineStyles from 'utils/combineStyles';

import AddIcon from '@material-ui/icons/Add';

import DashboardStyles from 'styles/dashboard';
import ProductsStyles from 'styles/movies';

import DownloadsCounter from 'pages/product/DownloadsCounter';
import { project } from 'project';

const CollectionsHeader = ({
  classes,
  searchQuery,
  setSearchQuery,
  productsLength,
}: any) => {
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <div
        className={`${classes.headerContainer} ${classes.marginHeaderContainer}`}
      >
        <Typography component='h4' className={classes.dashboardTitle}>
          {isSeller() ? (
            <>
              My Films{' '}
              <span className={classes.numberResults}>
                ({productsLength} {productsLength === 1 ? 'result' : 'results'})
              </span>
            </>
          ) : (
            <>
              Films{' '}
              <span className={classes.numberResults}>
                ({productsLength} {productsLength === 1 ? 'result' : 'results'})
              </span>
            </>
          )}
        </Typography>

        <TextField
          variant='outlined'
          value={searchQuery}
          onChange={(e: any) => handleSearch(e)}
          className={classes.searchField}
          placeholder='Search'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        {isSeller() ? (
          <div className={`${classes.buttonsContainer} ${classes.buttonsOrg}`}>
            <NavLink
              className={classes.dashLinkBtn}
              style={{ marginRight: '0' }}
              to='/movie/create'
            >
              <Button className={classes.dashboardButton} variant='outlined'>
                <span className='btn-txt'>Add Film</span>{' '}
                <AddIcon className={classes.btnIcon} />
              </Button>
            </NavLink>
          </div>
        ) : (
          ''
        )}
        {isBuyer() && project !== 'scene' ? <DownloadsCounter /> : ''}
      </div>
    </>
  );
};

const combinedStyles = combineStyles(DashboardStyles, ProductsStyles);

export default withStyles(combinedStyles as any)(CollectionsHeader);
