import { project } from 'project';

import LogoScene from 'assets/logo/logo-scene.png';
import LogoMAP from 'assets/logo/logo-white.svg';
import LogoMFH from 'assets/logo/mfh-logo.png';

interface ProjectFieldsConfig {
  language: boolean;
  ISAN: boolean;
  contentRating: boolean;
  credits: boolean;
  regions: boolean;
}

interface ProjectInterfaceConfig {
  name: string;
  logo: string;
}

interface ProjectConfigInterface {
  [key: string]: ProjectInterfaceConfig;
}
interface ProjectFldsConfig {
  [key: string]: ProjectFieldsConfig;
}

export const projectFieldsConfig: ProjectFldsConfig = {
  default: {
    language: true,
    ISAN: true,
    regions: false,
    contentRating: true,
    credits: false,
  },
  mfh: {
    language: false,
    ISAN: false,
    regions: true,
    contentRating: false,
    credits: true,
  },
};

export const projectImageConfig: ProjectConfigInterface = {
  default: {
    name: 'MAP Marketplace',
    logo: LogoMAP,
  },
  mfh: {
    name: 'Make Film History',
    logo: LogoMFH,
  },
  map: {
    name: 'MAP Marketplace',
    logo: LogoMAP,
  },
  scene: {
    name: 'Scene Project',
    logo: LogoScene,
  },
};

export const showField = (field: keyof ProjectFieldsConfig): boolean => {
  const projectConfig =
    projectFieldsConfig[project as string] || projectFieldsConfig.default;
  return projectConfig[field];
};
