import { Box, Button, FormLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import SearchIcon from '@mui/icons-material/Search';

import {
  IconButton,
  InputAdornment,
  Popover,
  TextareaAutosize,
  TextField,
} from '@mui/material';

import CheckIcon from 'assets/icons/check.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import EditIcon from 'assets/icons/edit.svg';
import TrashIcon from 'assets/icons/trashIcon.svg';

import OptionsIcon from 'assets/icons/optionsIcon.svg';
import OptionsIcon1 from 'assets/icons/optionsIcon1.svg';
import ThreeDots from 'assets/icons/threeDots.svg';

import Swal from 'sweetalert2';

import { withStyles } from '@material-ui/styles';
import Styles from 'styles/management';

import Loading from 'components/loadingButton/LoadingBtn';

import { QueryClient, QueryClientProvider } from 'react-query';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'styles/colors';

interface ManagementTableProps {
  usersData: any[];
  orgsData: any[];
  classes: any;
  tabType: 'Tutors' | 'Institutions';
  userApproveMutation: any;
  userDeleteMutation: any;
  orgApproveMutation: any;
  orgEditMutation: any;
  orgDeleteMutation: any;
}

interface UserData {
  id: number;
  name: string;
  email: string;
  info: string;
  status: string;
}

interface InstitutionData {
  id: number;
  name: string;
  address: string;
  domain: string;
  status: string;
}

type RowData = UserData | InstitutionData;

const queryClient = new QueryClient();

const ManagementTableWrapper: React.FC<ManagementTableProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ManagementTable {...props} />
    </QueryClientProvider>
  );
};

const ManagementTable: React.FC<ManagementTableProps> = ({
  usersData,
  orgsData,
  classes,
  tabType,
  userApproveMutation,
  userDeleteMutation,
  orgApproveMutation,
  orgEditMutation,
  orgDeleteMutation,
}) => {
  const [pendingRowsUser, setPendingRowsUser] = useState<RowData[]>([]);
  const [pendingRowsInst, setPendingRowsInst] = useState<RowData[]>([]);
  const [registeredRowsUser, setRegisteredRowsUser] = useState<RowData[]>([]);
  const [registeredRowsInst, setRegisteredRowsInst] = useState<RowData[]>([]);
  const [clickop, setClickop] = useState<HTMLButtonElement | null>(null);
  const [value, setValue] = useState('PENDING');
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    setPendingRowsUser(
      getRows(usersData, 'Tutors', ['pendingAdminValidation'])
    );
    setRegisteredRowsUser(getRows(usersData, 'Tutors', ['accepted']));
  }, [usersData]);

  useEffect(() => {
    setPendingRowsInst(getRows(orgsData, 'Institutions', ['pending']));
    setRegisteredRowsInst(getRows(orgsData, 'Institutions', ['accepted']));
  }, [orgsData]);

  //EDIT CELLS---------------------------------
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedRow, setEditedRow] = useState<RowData | null>(null);

  const [approvingRowId, setApprovingRowId] = useState<number | null>(null);

  const handleEdit = (row: RowData) => {
    setIsEditing(true);
    setEditedRow(row);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedRow(null);
  };

  const isCellEditable = (row: RowData): boolean => {
    return isEditing && editedRow?.id === row.id;
  };

  const getCellValue = (row: RowData, field: string): string => {
    if (isEditing && editedRow?.id === row.id) {
      return (editedRow as any)[field];
    }
    return (row as any)[field];
  };

  const handleCellEdit = (
    field: keyof UserData | keyof InstitutionData,
    value: string
  ) => {
    if (editedRow) {
      const updatedRow = { ...editedRow };
      (updatedRow as any)[field] = value;
      setEditedRow(updatedRow);
    }
  };
  //---------------------------------------------

  function createUserData(
    id: number,
    name: string,
    email: string,
    info: string,
    status: string
  ): UserData {
    // console.log(id, name, email, info, status);
    return { id, name, email, info, status };
  }

  function createInstitutionData(
    id: number,
    name: string,
    address: string,
    domain: string,
    status: string
  ): InstitutionData {
    return { id, name, address, domain, status };
  }

  const getRows = (
    data: any[],
    tabType: 'Tutors' | 'Institutions',
    status: string[]
  ): RowData[] => {
    if (tabType === 'Tutors') {
      return data
        .filter((user) => status.includes(user.status))
        .map((user) =>
          createUserData(
            user.id,
            `${user.firstname} ${user.lastname}`,
            user.email,
            user.info,
            user.status
          )
        );
    } else if (tabType === 'Institutions') {
      return data
        .filter((org) => status.includes(org.status))
        .map((org) =>
          createInstitutionData(
            org.id,
            org.name,
            org.address,
            org.domain,
            org.status
          )
        );
    }
    return [];
  };

  const rows: RowData[] =
    value === 'PENDING'
      ? tabType === 'Tutors'
        ? pendingRowsUser
        : pendingRowsInst
      : tabType === 'Tutors'
        ? registeredRowsUser
        : registeredRowsInst;

  const tableHeaders =
    tabType === 'Tutors'
      ? ['Id', 'Full name', 'Email Address', 'Information']
      : ['Id', 'Institution name', 'Institution address', 'Domain'];

  const filteredRows = rows.filter((row) => {
    if (tabType === 'Tutors') {
      const user = row as UserData;
      return (
        user.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.info?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else {
      const institution = row as InstitutionData;
      return (
        institution.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        institution.domain?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  });

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleApprove = (row: RowData, type: 'Tutors' | 'Institutions') => {
    setApprovingRowId(row.id);
    if (type === 'Tutors') {
      userApproveMutation.mutate({
        userId: row.id,
        status: 'accepted',
      });
    } else if (type === 'Institutions') {
      orgApproveMutation.mutate({
        id: row.id,
        info: { status: 'accepted' },
      });
    }
  };

  const handleReject = (row: RowData, type: 'Tutors' | 'Institutions') => {
    Swal.fire({
      html: `Are you sure you want delete <b>${row.name}</b>?`,
      // position: 'top-end',
      icon: 'warning',
      iconColor: SECONDARY_COLOR,
      showCancelButton: true,
      confirmButtonColor: SECONDARY_COLOR,
      cancelButtonColor: PRIMARY_COLOR,
      confirmButtonText: 'Yes',
      customClass: {
        container: classes.swalContainer,
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (type === 'Tutors' && 'email' in row) {
          userDeleteMutation.mutate({ userId: row.id, email: row.email });
        } else {
          orgDeleteMutation.mutate({ id: row.id });
        }
        setClickop(null);
      }
      setClickop(null);
    });
  };

  const handleEditOrg = (row: RowData) => {
    if ('address' in row) {
      setIsEditing(false);
      orgEditMutation.mutate({
        id: row.id,
        info: { name: row.name, address: row.address, domain: row.domain },
      });
      setEditedRow(null);
    }
  };

  //
  const handleClickThreeDots = (event: React.MouseEvent<HTMLButtonElement>) => {
    setClickop(event.currentTarget);
  };
  const handleCloseThreeDots = () => {
    setClickop(null);
  };

  //
  const open = Boolean(clickop);
  const id = open ? 'simple-popover' : undefined;
  //

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.tableContainer}>
        <Box className={classes.insideContainer}>
          <Box className={classes.headerContainer}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              className={`${classes.labelBlack}`}
              textColor='primary'
              indicatorColor='primary'
              aria-label='secondary tabs example'
            >
              <Tab value='PENDING' label='PENDING' />
              <Tab value='REGISTERED' label='REGISTERED' />
            </Tabs>
          </Box>

          <TextField
            variant='outlined'
            value={searchQuery}
            onChange={handleSearch}
            className={classes.searchField}
            placeholder='Search'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: 650,
              }}
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header) => (
                    <TableCell key={header} align='left'>
                      {header}
                    </TableCell>
                  ))}
                  {value === 'REGISTERED' ? (
                    <TableCell align='center'>
                      <img src={OptionsIcon1} />
                    </TableCell>
                  ) : (
                    <TableCell align='center'>
                      <img src={OptionsIcon} />
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {tabType === 'Tutors' ? (
                      <>
                        <TableCell>{row.id}</TableCell>
                        <TableCell align='left'>{row.name}</TableCell>
                        <TableCell align='left'>
                          {(row as UserData).email}
                        </TableCell>
                        <TableCell align='left'>
                          <div className={classes.infoBox}>
                            {(row as UserData).info}
                          </div>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>{row.id}</TableCell>
                        <TableCell align='left' width={400}>
                          {isCellEditable(row) ? (
                            <TextareaAutosize
                              value={getCellValue(row, 'name')}
                              className={classes.editMode}
                              onChange={(e) =>
                                handleCellEdit('name', e.target.value)
                              }
                            />
                          ) : (
                            getCellValue(row, 'name')
                          )}
                        </TableCell>
                        <TableCell align='left' width={400}>
                          {isCellEditable(row) ? (
                            <TextareaAutosize
                              value={getCellValue(row, 'address')}
                              className={classes.editMode}
                              onChange={(e) =>
                                handleCellEdit('address', e.target.value)
                              }
                            />
                          ) : (
                            getCellValue(row, 'address')
                          )}
                        </TableCell>
                        <TableCell align='left' width={400}>
                          {isCellEditable(row) ? (
                            <TextareaAutosize
                              value={getCellValue(row, 'domain')}
                              className={classes.editMode}
                              onChange={(e) =>
                                handleCellEdit('domain', e.target.value)
                              }
                            />
                          ) : (
                            getCellValue(row, 'domain')
                          )}
                        </TableCell>
                      </>
                    )}
                    {value === 'PENDING' ? (
                      <TableCell align='center'>
                        <div className={classes.btnsDiv}>
                          {isEditing === true && editedRow?.id === row.id ? (
                            <></>
                          ) : (
                            <>
                              <Button
                                className={`${classes.buttonsOp} ${classes.approveBtn}`}
                                onClick={() => handleApprove(row, tabType)}
                              >
                                {userApproveMutation.isLoading &&
                                approvingRowId === row.id ? (
                                  <Loading />
                                ) : (
                                  <>
                                    <img
                                      className={classes.iconStyle}
                                      src={CheckIcon}
                                    />{' '}
                                    Approve
                                  </>
                                )}
                              </Button>
                              <Button
                                className={`${classes.buttonsOp} ${classes.rejectBtn}`}
                                onClick={() => handleReject(row, tabType)}
                              >
                                <img
                                  className={classes.iconStyle}
                                  src={DeleteIcon}
                                />{' '}
                                Reject
                              </Button>
                            </>
                          )}
                          {tabType === 'Tutors' ? (
                            <></>
                          ) : (
                            <>
                              {isEditing === true &&
                              editedRow?.id === row.id ? (
                                <>
                                  <div className={`${classes.buttonsOp}`} />
                                  <Button
                                    className={`${classes.buttonsOp} ${classes.approveBtn}`}
                                    onClick={() => handleEditOrg(editedRow)}
                                  >
                                    <img
                                      className={classes.iconStyle}
                                      src={CheckIcon}
                                    />{' '}
                                    Save
                                  </Button>
                                  <Button
                                    className={`${classes.buttonsOp} ${classes.rejectBtn}`}
                                    onClick={handleCancelEdit}
                                  >
                                    <img
                                      className={classes.iconStyle}
                                      src={DeleteIcon}
                                    />{' '}
                                    Cancel
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    className={`${classes.buttonsOp} ${classes.editBtn}`}
                                    onClick={() => handleEdit(row)}
                                  >
                                    <img
                                      className={classes.iconStyle}
                                      src={EditIcon}
                                    />{' '}
                                    Edit
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </TableCell>
                    ) : (
                      <TableCell align='center'>
                        <div className={classes.btnsDiv}>
                          <IconButton
                            aria-describedby={id}
                            onClick={handleClickThreeDots}
                          >
                            <img src={ThreeDots} />
                          </IconButton>
                          <Popover
                            id={id}
                            open={open}
                            anchorEl={clickop}
                            onClose={handleCloseThreeDots}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                          >
                            <Button
                              className={`${classes.popoverBtn} ${classes.rejectBtn}`}
                              onClick={() => handleReject(row, tabType)}
                            >
                              <img
                                className={classes.iconStyle}
                                src={TrashIcon}
                              />{' '}
                              Delete
                            </Button>
                          </Popover>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {filteredRows.length === 0 ? (
                  <TableCell colSpan={tableHeaders.length + 1} align='center'>
                    <FormLabel className={classes.labelSimple}>
                      No values
                    </FormLabel>
                  </TableCell>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default withStyles(Styles as any)(ManagementTableWrapper);
