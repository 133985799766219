import LogoAHRC from 'assets/logo/partners/AHRC_logo.png';
import LogoBBC from 'assets/logo/partners/BBC_logo.svg';
import LogoBFI from 'assets/logo/partners/BFI_logo.svg';
import LogoIFI from 'assets/logo/partners/IFI_logo.jpg';
import LogoKU from 'assets/logo/partners/KU_KSA_logo.jpg';
import LogoNIS from 'assets/logo/partners/NIS_logo.png';
import LogoUCC from 'assets/logo/partners/UCC_logo.jpg';
import React from 'react';

import LogoMap from 'assets/logo/logo-black.svg';

import './partnersFooter.css';

const PartnersFooter: React.FC = () => {
  return (
    <div className='partners-logos'>
      <p className='poweredByText'>
        Powered by:{' '}
        <span>
          <img className='img-span' src={LogoMap} alt='Logo' />
        </span>
      </p>

      <a href='https://www.ucc.ie' target='_blank' rel='noopener noreferrer'>
        <img src={LogoUCC} alt='Partner 1' />
      </a>
      <a
        href='https://www.northernirelandscreen.co.uk'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={LogoNIS} alt='Partner 2' />
      </a>
      <a
        href='https://www.kingston.ac.uk/faculties/kingston-school-of-art/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={LogoKU} alt='Partner 3' />
      </a>
      <a href='https://www.ifi.ie/' target='_blank' rel='noopener noreferrer'>
        <img src={LogoIFI} alt='Partner 4' />
      </a>
      <a
        href='https://www.bfi.org.uk'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={LogoBFI} alt='Partner 5' />
      </a>
      <a
        href='https://www.bbc.com/topics/c01yxyz46myt'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={LogoBBC} alt='Partner 6' />
      </a>
      <a
        href='https://www.ukri.org/councils/ahrc/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={LogoAHRC} alt='Partner 7' />
      </a>
    </div>
  );
};

export default PartnersFooter;
