import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import { VideoPlayer } from '@videojs-player/react';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { isBuyer } from 'services/authService';
import { watchProductMedia } from 'services/productService';
import DashboardStyles from 'styles/dashboard';
import combineStyles from 'utils/combineStyles';
import 'video.js/dist/video-js.css';
import MovieStyles from './PStyles';

import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

import ReactPlayer from 'react-player';

import LogoMFH from 'assets/logo/mfh-logo.png';
import { project } from 'project';
import './videoPlayer.css';

interface OwnProps {
  classes?: any;
  id: string;
  openPopup: boolean;
  dialogTitle: string;
  setOpenPopup: (value: boolean) => void | undefined;
}

function VideoPopup(props: OwnProps) {
  const { classes, openPopup, id, setOpenPopup } = props;

  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [isVideoLoaded, setVideoLoaded] = useState(false);
  const [isVisible, setIsVisible] = useState(openPopup);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const playerRef = useRef<ReactPlayer | null>(null);
  const [volume, setVolume] = useState(1);

  const getVolumeIcon = () => {
    if (volume === 0) return <VolumeOffIcon />;
    if (volume > 0 && volume <= 0.5) return <VolumeDownIcon />;
    return <VolumeUpIcon />;
  };

  const { data: videoData, refetch } = useQuery(
    'watchVideo',
    async () => {
      return await watchProductMedia(id, isBuyer());
    },
    {
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (!openPopup) {
      setVideoLoaded(false);
    }
  }, [openPopup]);

  useEffect(() => {
    if (openPopup && videoData) {
      setVideoUrl(videoData.url);
      refetch();
      setIsVisible(true);
    } else {
      setVideoUrl(null);
      setIsVisible(false);
    }
  }, [openPopup, videoData]);

  useEffect(() => {
    if (openPopup) {
      const interval = setInterval(() => {
        const container = document.querySelector('.video-container');
        const watermark = document.querySelector('.watermark');

        if (!watermark && container) {
          const wm = document.createElement('i');
          wm.className = 'watermark';
          wm.innerHTML = `<img src=${LogoMFH} />`;
          container.appendChild(wm);
        } else if (watermark) {
          const img = watermark.querySelector('img');
          if (!img) {
            watermark.innerHTML = `<img src=${LogoMFH} />`;
          }
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [openPopup]);

  const handlePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = parseFloat(e.target.value) * duration;
    setProgress(parseFloat(e.target.value));
    playerRef.current?.seekTo(newTime);
  };

  const handleProgress = (state: { played: number; playedSeconds: number }) => {
    setProgress(state.played);
    setCurrentTime(state.playedSeconds);
  };

  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const onClose = () => {
    setOpenPopup(false);
    setIsVisible(false);
    setIsPlaying(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Dialog open={openPopup} onClose={onClose} maxWidth='xl'>
      <DialogContent className={classes.popupContainer}>
        <div className={classes.videoHeader}>
          <Typography variant='h5' className={classes.dashboardSubtitleBlue}>
            {props.dialogTitle}
          </Typography>

          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className={classes.videoContainer}>
          {videoUrl && (
            <>
              {project === 'mfh' ? (
                <div className='video-container'>
                  <ReactPlayer
                    ref={playerRef}
                    url={videoUrl}
                    playing={isPlaying}
                    controls={false}
                    volume={volume}
                    pip={false}
                    className='video'
                    onProgress={handleProgress}
                    onDuration={handleDuration}
                    config={{
                      file: {
                        attributes: {
                          poster: videoData?.poster,
                          // controlsList: 'nofullscreen',
                          disablePictureInPicture: true,
                          onContextMenu: (e: any) => e.preventDefault(),
                        },
                      },
                    }}
                  />

                  <i className='watermark'>
                    <img src={LogoMFH} />
                  </i>

                  <div className='custom-controls'>
                    <button
                      onClick={handlePlayPause}
                      className='play-pause-btn'
                    >
                      {isPlaying ? (
                        <PauseIcon fontSize='large' />
                      ) : (
                        <PlayArrowIcon fontSize='large' />
                      )}
                    </button>
                    <div className='time-controls'>
                      <span className='current-time'>
                        {formatTime(currentTime)}
                      </span>
                      <input
                        type='range'
                        min='0'
                        max='1'
                        step='0.01'
                        value={progress}
                        onChange={handleSeek}
                        className='seek-bar'
                      />
                      <span className='duration'>{formatTime(duration)}</span>
                    </div>
                    <div className='volume-controls'>
                      <IconButton
                        onClick={() => setVolume(volume === 0 ? 1 : 0)}
                        style={{ color: 'white' }}
                      >
                        {getVolumeIcon()}
                      </IconButton>
                      <input
                        type='range'
                        min='0'
                        max='1'
                        step='0.01'
                        value={volume}
                        onChange={(e) => setVolume(parseFloat(e.target.value))}
                        className='volume-bar'
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <VideoPlayer
                  className={`video-js vjs-big-play-centered ${
                    isVideoLoaded ? '' : 'hidden'
                  }`}
                  controls
                  poster={videoData?.poster}
                  sources={[
                    {
                      src: videoUrl,
                      type: videoData?.mime || '',
                    },
                  ]}
                  crossOrigin='*'
                  onReady={() => setVideoLoaded(true)}
                />
              )}
            </>
          )}
        </div>

        <div className={classes.productOptions}>
          <Button
            className={classes.dashboardButton}
            variant='outlined'
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const combinedStyles = combineStyles(DashboardStyles, MovieStyles);
export default withStyles(combinedStyles)(VideoPopup);
