import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';
import { SECONDARY_COLOR } from 'styles/colors';

export const modalStyle: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  maxHeight: '90vh',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '5px',
};

export const modalContractStyle: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: '#F6F4F2',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  maxHeight: '90vh',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '5px',
};

export const textFieldStyle: SxProps = {
  mb: 2,
  p: 2,
  mt: 2,
  flex: 1,
  backgroundColor: 'rgba(0, 0, 0, 0.12)',
  borderRadius: 1,
  height: '300px',
  overflowY: 'auto',
};

export const checkboxStyle: SxProps = {
  color: `${SECONDARY_COLOR} !important`,
  '& .Mui-checked': { color: `${SECONDARY_COLOR} !important` },
};

export const buttonStyle: SxProps = {
  backgroundColor: `${SECONDARY_COLOR}`,
  '&:hover': {
    backgroundColor: `${SECONDARY_COLOR} !important`,
  },
};

export const pdfViewerStyle: CSSProperties = {
  marginTop: '16px',
  flex: 1,
  height: '400px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderRadius: '5px',
};
