import { OrgsManagementData, PatchOrgInfoModel } from 'mapfilm-api';
import { api } from 'services/customAxios';

const getAllInstitutions = async (): Promise<OrgsManagementData> => {
  try {
    const res = await api.get('/admin/orgs');
    const orgs = res.data;
    return orgs;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const changeOrgInfo = async (id: number, info: PatchOrgInfoModel) => {
  try {
    let loadInfo: PatchOrgInfoModel = {};
    if (
      info.address !== undefined &&
      info.name !== undefined &&
      info.domain !== undefined
    ) {
      loadInfo = {
        name: info.name,
        address: info.address,
        domain: info.domain,
      };
    } else if (info.status !== undefined) {
      loadInfo = {
        status: info.status,
      };
    }

    const response = await api.patch(`/admin/orgs/${id}`, loadInfo);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const deleteOrg = async (id: number) => {
  try {
    const response = await api.delete(`/admin/orgs/${id}`, {
      headers: {
        id: id.toString(),
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export { changeOrgInfo, deleteOrg, getAllInstitutions };
