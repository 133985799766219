import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Loading from 'components/loading/Loading';
import ProductItem from 'pages/products/ItemProduct';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';
import combineStyles from 'utils/combineStyles';

import { getAllProducts } from 'services/productService';

import { LibraryProduct } from 'mapfilm-api';
import { hasLicense, isBuyer, isSeller, isStudent } from 'services/authService';
import DashboardStyles from 'styles/dashboard';
import ProductsStyles from 'styles/movies';

import FiltersSideBar from 'components/filtersSideBar/filtersSideBar';

import TermsUsageModal from 'components/modal/TermsUsageModal';
import { project } from 'project';
import CollectionsHeader from './CollectionsHeader';

import NoAccessUser from './NoAccessUser';

interface OwnProps {
  classes?: any;
  history: any;
  match: any;
}

const queryClient = new QueryClient();

const CollectionsPageWrapper: React.FC<OwnProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <CollectionsPage {...props} />
    </QueryClientProvider>
  );
};

const CollectionsPage: React.FC<OwnProps> = ({ classes }) => {
  const [products, setProducts] = useState([] as LibraryProduct[]);
  const [loading, setLoading] = useState(true as boolean);
  const [filteredProducts, setFilteredProducts] = useState(
    [] as LibraryProduct[]
  );
  const [searchQuery, setSearchQuery] = useState('' as string);
  const [countriesList, setCountriesList] = useState<string[]>([]);
  const currentYear = new Date().getFullYear();

  const {
    data: hasLicenseSigned,
    isLoading,
    error,
  } = useQuery(['licenseStatus'], hasLicense);

  const defaultValuesPlaceholder = {
    rightsOwner: 'placeholder',
    regions: [],
    year: [0, 0],
    duration: [0, 0],
    themes: [] as string[],
  };
  const [filters, setFilters] = useState(defaultValuesPlaceholder);
  const [defaultValues, setDefaultValues] = useState(defaultValuesPlaceholder);
  const [themesList, setThemesList] = useState<string[]>([]);
  const [rightsOwners, setRightsOwners] = useState([] as string[]);

  const [displayedProducts, setDisplayedProducts] = useState<LibraryProduct[]>(
    []
  );
  const [itemsToShow, setItemsToShow] = useState(10);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    //console.log(filters);
  }, [filters]);

  const {
    data: dataProducts,
    isLoading: loadingProducts,
    isError: errorProducts,
  } = useQuery(['products', isSeller()], () => getAllProducts(isSeller()), {
    onSuccess: (data) => {
      setLoading(false);
      const products = data.products;
      setProducts(products);
      setFilteredProducts(products);
      setDisplayedProducts(products.slice(0, itemsToShow));
      setCountriesList(data.locations);
      setThemesList(data.genres);

      const uniqueRightsOwners = Array.from(
        new Set(
          products
            .map((product: any) => product?.owner)
            .filter((owner: any) => owner !== undefined)
        )
      );
      setRightsOwners(uniqueRightsOwners as string[]);

      const minDuration = Math.min(
        ...products
          .map((product: LibraryProduct) => product.duration)
          .filter((duration: any) => typeof duration === 'number')
      );
      const maxDuration = Math.max(
        ...products
          .map((product: LibraryProduct) => product.duration)
          .filter((duration: any) => typeof duration === 'number')
      );
      const minYear = Math.min(
        ...products
          .map((product: LibraryProduct) => product.year)
          .filter((year: any) => typeof year === 'number')
      );
      const maxYear = Math.max(
        ...products
          .map((product: LibraryProduct) => product.year)
          .filter((year: any) => typeof year === 'number')
      );

      const filtersDefaultValues = {
        rightsOwner: 'placeholder',
        regions: [],
        year: [minYear, maxYear],
        duration: [minDuration, maxDuration],
        themes: [],
      };
      setDefaultValues(filtersDefaultValues);
      setFilters(filtersDefaultValues);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    // console.log(dataProducts);
  }, [dataProducts, loadingProducts]);

  useEffect(() => {
    // console.log(filteredProducts);
  }, [filteredProducts]);

  useEffect(() => {
    if (searchQuery) {
      const preFilteredProducts = products.filter(
        (product: LibraryProduct) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.synopsis?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredProducts(preFilteredProducts);
    } else if (JSON.stringify(filters) !== JSON.stringify(defaultValues)) {
      let preFilteredProducts = products;
      if (
        filters.duration[0] != defaultValues.duration[0] ||
        filters.duration[1] != defaultValues.duration[1]
      ) {
        preFilteredProducts = preFilteredProducts.filter(
          (product: LibraryProduct) => {
            return (
              product.duration &&
              product.duration <= filters.duration[1] &&
              product.duration >= filters.duration[0]
            );
          }
        );
      }
      if (
        filters.year[0] != defaultValues.year[0] ||
        filters.year[1] != defaultValues.year[1]
      ) {
        preFilteredProducts = preFilteredProducts.filter(
          (product: LibraryProduct) => {
            return (
              product.year &&
              product.year <= filters.year[1] &&
              product.year >= filters.year[0]
            );
          }
        );
      }
      if (filters.themes.length > 0) {
        preFilteredProducts = preFilteredProducts.filter(
          (product: LibraryProduct) => {
            return product.genres?.some(
              (genre: any) =>
                filters.themes.find((theme: any) => theme === genre) !==
                undefined
            );
          }
        );
      }
      if (filters.regions.length > 0) {
        preFilteredProducts = preFilteredProducts.filter(
          (product: LibraryProduct) => {
            return product.regions?.some(
              (region: any) =>
                filters.regions.find((op: any) => op === region) !== undefined
            );
          }
        );
      }

      if (filters.rightsOwner !== 'placeholder') {
        preFilteredProducts = preFilteredProducts.filter(
          (product: LibraryProduct) => product.owner === filters.rightsOwner
        );
      }
      setFilteredProducts(preFilteredProducts);
    } else {
      setFilteredProducts(products);
    }
  }, [searchQuery, filters, defaultValues]);

  useEffect(() => {
    setDisplayedProducts(filteredProducts.slice(0, itemsToShow));
  }, [filteredProducts, itemsToShow]);

  const loadMoreProducts = useCallback(() => {
    setItemsToShow((prev) => prev + 10);
  }, []);

  const lastProductRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMoreProducts();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, loadMoreProducts]
  );

  const CollectionsLoading = (
    <>
      <CollectionsHeader />
      <Loading />
    </>
  );

  /******** For terms of use modal */
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const hasAgreed = localStorage.getItem('hasAgreedToTerms');
    if (!hasAgreed) {
      setIsModalOpen(true);
    }
  }, []);

  const handleCloseModal = () => {
    localStorage.setItem('hasAgreedToTerms', 'true');

    setIsModalOpen(false);
  };
  /*************************** */

  return (
    <Suspense fallback={CollectionsLoading}>
      {isStudent() &&
      (hasLicenseSigned == null || hasLicenseSigned === false) &&
      project === 'mfh' ? (
        <NoAccessUser classes={classes} />
      ) : (
        <>
          {isBuyer() && project === 'mfh' && (
            <TermsUsageModal
              open={isModalOpen}
              handleClose={handleCloseModal}
            />
          )}
          <CollectionsHeader
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            productsLength={filteredProducts?.length}
          />
          {loading || loadingProducts ? (
            <Loading />
          ) : (
            <>
              <Grid container>
                <Grid item xs={9}>
                  {typeof products !== 'undefined' &&
                  filteredProducts.length === 0 ? (
                    <Typography className={classes.noProducts}>
                      No film products!
                    </Typography>
                  ) : (
                    <Grid container spacing={3}>
                      <Grid item xs={12} style={{ marginRight: '20px' }}>
                        <Grid container spacing={3}>
                          {displayedProducts.map(
                            (product: LibraryProduct, index: number) => (
                              <Grid
                                key={product.id + index.toString()}
                                item
                                xs={12}
                                ref={
                                  index === displayedProducts.length - 1
                                    ? lastProductRef
                                    : null
                                }
                              >
                                <NavLink
                                  key={index}
                                  className={classes.productLink}
                                  to={'/movies/' + product.id}
                                >
                                  <ProductItem
                                    key={index + '_product'}
                                    poster={product.posterUrl}
                                    title={product.name}
                                    director={product.director}
                                    duration={product.duration}
                                    year={product.year}
                                    synopsis={product.synopsis}
                                    genres={product.genres}
                                    genreList={themesList}
                                    movie={false}
                                  />
                                </NavLink>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <FiltersSideBar
                    countriesList={countriesList as string[]}
                    filters={filters}
                    setFilters={setFilters}
                    defaultValues={[defaultValues, setDefaultValues]}
                    themesList={themesList}
                    setThemesList={setThemesList}
                    products={products as LibraryProduct[]}
                    rightsOwners={rightsOwners}
                    currentYear={currentYear}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </Suspense>
  );
};

const combinedStyles = combineStyles(DashboardStyles, ProductsStyles);

export default withStyles(combinedStyles as any)(CollectionsPageWrapper);
