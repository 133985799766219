import { createStyles } from '@material-ui/styles';

import { PRIMARY_COLOR, SECONDARY_COLOR, THIRD_COLOR } from './colors';

const useStyles = createStyles({
  authOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  toogleButton: {
    // backgroundColor: PRIMARY_COLOR,
    // border: `1px solid ${PRIMARY_COLOR}`,
    border: 'none',
    width: '50%',
    display: 'flex',
    // marginBottom: '30px',
  },
  toggleButtonHalf: {
    flex: '1',
  },
  selectedToggle: {
    backgroundColor: `${SECONDARY_COLOR} !important`,
    color: `${THIRD_COLOR} !important`,
    fontWeight: 'bold',
  },
  notSelectedToggle: {
    border: `1px solid ${PRIMARY_COLOR} !important`,
    color: `${PRIMARY_COLOR} !important`,
    fontWeight: 'bold',
  },
  mainContainer: {
    width: '100%',
    height: '100%',
  },
  tableContainer: {
    marginTop: '20px',
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '10px',
    border: `1px solid #E0E0E0`,
    position: 'relative',
    zIndex: 2,
  },
  insideContainer: {
    width: '100%',
    height: '100%',
    padding: '25px',
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: '20px',
  },
  searchField: {
    width: '385px !important',
    marginBottom: '20px !important',
    '& .MuiOutlinedInput-input': {
      height: '10px !important',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1.5px solid black',
    },
  },
  labelBlack: {
    fontSize: 16,
    fontWeight: 900,
    color: `${PRIMARY_COLOR} !important`,
    textTransform: 'uppercase',
    lineHeight: '19px',
    paddingBottom: 8,
    '& .MuiTabs-indicator': {
      backgroundColor: `${SECONDARY_COLOR} !important`,
    },
    '& .MuiTab-root': {
      '&.Mui-selected': {
        color: SECONDARY_COLOR,
      },
      color: `${PRIMARY_COLOR}`,
    },
  },
  labelSimple: {
    fontSize: '0.875rem',
    fontWeight: 600,
    color: `${PRIMARY_COLOR} !important`,
    // textTransform: 'uppercase',
    lineHeight: '19px',
    '& .MuiTabs-indicator': {
      backgroundColor: `${SECONDARY_COLOR} !important`,
    },
    '& .MuiTab-root': {
      '&.Mui-selected': {
        color: SECONDARY_COLOR,
      },
      color: `${PRIMARY_COLOR}`,
    },
  },
  infoBox: {
    backgroundColor: '#F0F0F0',
    padding: '10px',
    borderRadius: '5px',
  },
  buttonsOp: {
    width: 104,
    height: 27,
    // paddingRight: 20,
    paddingLeft: 14,
    marginBottom: 8,
    color: THIRD_COLOR,
    borderRadius: 5,
    textTransform: 'capitalize',
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: '0.02em',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  popoverBtn: {
    width: 104,
    height: 27,
    paddingRight: 20,
    paddingLeft: 20,
    color: THIRD_COLOR,
    borderRadius: 5,
    textTransform: 'capitalize',
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: '0.02em',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  approveBtn: {
    backgroundColor: '#14A611',
    border: `2px solid #14A611`,
    '&:hover': {
      color: THIRD_COLOR,
      backgroundColor: '#0F800D',
    },
  },
  btnsDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  rejectBtn: {
    backgroundColor: '#E73232',
    border: `2px solid #E73232`,
    '&:hover': {
      color: THIRD_COLOR,
      backgroundColor: '#C92A2A',
    },
  },
  editBtn: {
    backgroundColor: '#C3C3C3',
    border: `2px solid #C3C3C3`,
    '&:hover': {
      color: THIRD_COLOR,
      backgroundColor: '#A0A0A0',
    },
  },
  iconStyle: {
    marginRight: 8,
  },
  editMode: {
    resize: 'none',
    width: '100%',
    '& .MuiInputBase-root': {
      fontSize: '0.875rem !important',
    },
    '& .MuiOutlinedInput-input': {
      height: '10px !important',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1.5px solid black',
    },
  },
  swalContainer: {
    zIndex: 99999,
  },

  resetDownloadsButton: {
    minWidth: 160,
    height: 40,
    paddingRight: 20,
    paddingLeft: 20,
    backgroundColor: PRIMARY_COLOR,
    color: THIRD_COLOR,
    border: 'none',
    borderRadius: 5,
    textTransform: 'capitalize',
    fontWeight: 500,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    letterSpacing: '0.02em',
    '&.Mui-disabled': {
      color: THIRD_COLOR,
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      border: 'none',
    },
    '&:hover': {
      backgroundColor: SECONDARY_COLOR,
      color: THIRD_COLOR,
      '& .rotateIcon': {
        animation: `$rotate360 0.6s ease-in-out`,
      },
    },
  },
  '@keyframes rotate360': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  btnDownloadReset: {
    border: 'none !important',
    width: '50% !important',
    display: 'flex !important',
    backgroundColor: `${SECONDARY_COLOR} !important`,
    color: `${THIRD_COLOR} !important`,
    fontWeight: 'bold',
  },
  btnDownloadCancel: {
    border: 'none !important',
    width: '50% !important',
    display: 'flex !important',
    backgroundColor: `${PRIMARY_COLOR} !important`,
    color: `${THIRD_COLOR} !important`,
    fontWeight: 'bold',
  },
});

export default useStyles;
