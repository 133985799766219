import { withStyles } from '@material-ui/styles';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputBase,
} from '@mui/material';
import React, { useState } from 'react';
import { SECONDARY_COLOR } from 'styles/colors';
import Styles from 'styles/management';

interface ResetDownloadsModalProps {
  open: boolean;
  onClose: () => void;
  onResetDownloads: (date: string, downloads: number) => void;
  classes: any;
}

const ResetDownloadsModal: React.FC<ResetDownloadsModalProps> = ({
  classes,
  open,
  onClose,
  onResetDownloads,
}) => {
  const defaultDownloads = 10;

  const currentYear = new Date().getFullYear();
  const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0');
  const currentDay = String(new Date().getDate()).padStart(2, '0');
  const defaultDate = `${currentYear}-${currentMonth}-${currentDay}`;

  const [editDate, setEditDate] = useState(defaultDate);
  const [editDownloads, setEditDownloads] = useState(defaultDownloads);
  const [isEditing, setIsEditing] = useState(false);
  const [savedDate, setSavedDate] = useState(defaultDate);
  const [savedDownloads, setSavedDownloads] = useState(defaultDownloads);

  const handleEdit = () => {
    setSavedDate(editDate);
    setSavedDownloads(editDownloads);
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setEditDate(savedDate);
    setEditDownloads(savedDownloads);
    setIsEditing(false);
  };

  const handleSaveEdit = () => {
    setIsEditing(false);
    setSavedDate(editDate);
    setSavedDownloads(editDownloads);
  };

  const handleDownloadsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(1, Number(e.target.value));
    setEditDownloads(value);
  };

  const handleReset = () => {
    onResetDownloads(editDate, editDownloads);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '29%',
          maxWidth: 'none',
        },
      }}
    >
      <Box display='flex' justifyContent='flex-end' pr={2}>
        <IconButton
          onClick={handleEdit}
          style={{ color: SECONDARY_COLOR, marginTop: '10px' }}
        >
          <EditIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ padding: '0px 20px 24px 20px' }}>
        <Box display='flex' flexDirection='column' gap={2}>
          <Box display='flex' flexDirection='column'>
            <label className={classes.labelBlack}>Reset Date</label>
            <InputBase
              type='date'
              value={editDate}
              onChange={(e) => setEditDate(e.target.value)}
              disabled={!isEditing}
              style={{
                border: '1px solid #ccc',
                borderRadius: 4,
                padding: '8px',
              }}
            />
          </Box>
          <Box display='flex' flexDirection='column'>
            <label className={classes.labelBlack}>Number of Downloads</label>
            <InputBase
              type='number'
              value={editDownloads}
              onChange={handleDownloadsChange}
              disabled={!isEditing}
              style={{
                border: '1px solid #ccc',
                borderRadius: 4,
                padding: '8px',
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {isEditing ? (
          <>
            <Button
              onClick={handleCancelEdit}
              className={classes.btnDownloadCancel}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveEdit}
              className={classes.btnDownloadReset}
            >
              Save Changes
            </Button>
          </>
        ) : (
          <>
            <Button onClick={onClose} className={classes.btnDownloadCancel}>
              Cancel
            </Button>
            <Button onClick={handleReset} className={classes.btnDownloadReset}>
              Reset Downloads
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(Styles as any)(ResetDownloadsModal);
