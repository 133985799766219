import { withStyles } from '@material-ui/styles';
import React from 'react';

import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { project } from 'project';
import { projectImageConfig } from 'utils/projectFieldsDefinitions';
import UStyles from './UStyles';

interface Props {
  classes?: any;
  history: any;
  location: any;
}

const Unsupported: React.FC<Props> = ({ classes }) => {
  const { name, logo } =
    projectImageConfig[project] || projectImageConfig.default;

  return (
    <div className={classes.pageContainer}>
      <img className={classes.mapLogo} src={logo} alt={`${name} logo`} />
      <div className={classes.unsupportedContent}>
        <InfoRoundedIcon className={classes.unsupportedIcon} />

        <span className={classes.unsupportedTxt}>
          Oops! Screen Resolution Too Small
        </span>

        <span className={classes.unsupportedDescription}>
          {name} isn&apos;t optimized for your current screen size.{' '}
          <br className={classes.divisor} />
          For the best experience, please maximize your window or switch to a
          larger device (desktop computer or laptop).
        </span>
      </div>
    </div>
  );
};

export default withStyles(UStyles)(Unsupported);
