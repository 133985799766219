import { Component } from 'react';

import LoginMFH from 'assets/images/landingPageMFH.jpg';
import LoginImage from 'assets/images/login-img-example.svg';
import LoginScene from 'assets/images/login-side-scene.png';
import { project } from 'project';

// import LogoMap from 'assets/logo/logo-white.svg';

import './AuthSplash.css';

class AuthSplash extends Component {
  render() {
    return (
      <>
        <div className='auth-splash-container'>
          <div className='splash-bkg'>
            <div className='auth-logo'>
              {/* <NavLink to='/'>
                <div className='logo-container'>
                  <img className='logo-img' src={LogoImg} alt='Project Logo' />
                  <img src={LogoTxt} alt='Project Logo Txt' />
                </div>
              </NavLink> */}
            </div>
            {project === 'scene' ? (
              <img
                className='auth-image'
                src={LoginScene}
                alt='Login Illustration'
              />
            ) : (
              <>
                {project === 'mfh' ? (
                  <>
                    <img
                      className='auth-image'
                      src={LoginMFH}
                      alt='Login Illustration'
                    />
                    {/* <div className='copyright-disclaimer'>
                      <p>
                        Powered by:{' '}
                        <span>
                          <img
                            src={LogoMap}
                            style={{ paddingLeft: '10px' }}
                            alt='Logo'
                          />
                        </span>
                      </p>
                    </div> */}
                  </>
                ) : (
                  <>
                    <img
                      className='auth-image'
                      src={LoginImage}
                      alt='Login Illustration'
                    />
                  </>
                )}
                {/* <div className='copyright-disclaimer'>
                  <p>Copyright © MAP project 2024.</p>
                </div> */}
              </>
            )}
          </div>
        </div>

        <div className='auth-splash-hidden'>
          <div className='splash-bkg'>
            <img
              className='auth-image'
              src={LoginImage}
              alt='Login Illustration'
            />
          </div>
        </div>
      </>
    );
  }
}

export default AuthSplash;
