// export const allRegionsList = [
//   'East of England',
//   'England',
//   'Ireland',
//   'London',
//   'Midlands',
//   'North of England',
//   'Northeast',
//   'Northeast Ireland',
//   'Northwest',
//   'Scotland',
//   'Southeast',
//   'Southwest',
//   'Wales',
// ];

export const allRegionsList = [
  'EAST OF ENGLAND',
  'ENGLAND',
  'IRELAND',
  'LONDON',
  'MIDLANDS',
  'NORTH OF ENGLAND',
  'NORTHEAST',
  'NORTHEAST IRELAND',
  'NORTHWEST',
  'SCOTLAND',
  'SOUTHEAST',
  'SOUTHWEST',
  'WALES',
];
