import { Button } from '@material-ui/core';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, { useState } from 'react';
import { QueryClient, QueryClientProvider, useMutation } from 'react-query';
import {
  //resetDownloadsNumber,
  resetCustomDownloadsNumber,
} from 'services/productService';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'styles/colors';
import Swal from 'sweetalert2';
import ResetDownloadsModal from './ResetDownloadsModal';

interface ManagementHeaderProps {
  handleChangeTabType: (newValue: 'Tutors' | 'Institutions') => void;
  tabType: 'Tutors' | 'Institutions';
  classes: any;
}

const queryClient = new QueryClient();

const ManagementHeaderWrapper: React.FC<ManagementHeaderProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ManagementHeader {...props} />
    </QueryClientProvider>
  );
};

const ManagementHeader: React.FC<ManagementHeaderProps> = ({
  classes,
  handleChangeTabType,
  tabType,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const resetDownloadsMutation = useMutation(
    ({ downloads, date }: { downloads: number; date: Date }) =>
      resetCustomDownloadsNumber(downloads, date),
    {
      onError: (error: any) => {
        console.log(error);
      },
      onSuccess: () => {
        Swal.fire({
          icon: 'success',
          iconColor: SECONDARY_COLOR,
          text: 'Downloads reset with success',
          showConfirmButton: false,
          customClass: {
            container: classes.swalContainer,
          },
        });
      },
    }
  );

  const handleClickResetDownload = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleResetDownloads = (date: string, downloads: number) => {
    const dateOriginFormat = new Date(date);
    Swal.fire({
      html: `Are you sure you want reset downloads number?`,
      icon: 'warning',
      iconColor: SECONDARY_COLOR,
      showCancelButton: true,
      confirmButtonColor: SECONDARY_COLOR,
      cancelButtonColor: PRIMARY_COLOR,
      confirmButtonText: 'Yes',
      customClass: {
        container: classes.swalContainer,
      },
    }).then((result) => {
      if (result.isConfirmed) {
        resetDownloadsMutation.mutate({ downloads, date: dateOriginFormat });
      }
    });
    console.log(`Date: ${date}, Downloads: ${downloads}`);
  };

  return (
    <div className={classes.authOptions}>
      <ToggleButtonGroup
        className={classes.toogleButton}
        exclusive
        aria-label='Platform'
      >
        <ToggleButton
          className={`${classes.toggleButtonHalf} ${
            tabType === 'Tutors'
              ? classes.selectedToggle
              : classes.notSelectedToggle
          }`}
          onClick={() => handleChangeTabType('Tutors')}
          value='Tutors'
        >
          Tutors
        </ToggleButton>
        <ToggleButton
          className={`${classes.toggleButtonHalf} ${
            tabType === 'Institutions'
              ? classes.selectedToggle
              : classes.notSelectedToggle
          }`}
          onClick={() => handleChangeTabType('Institutions')}
          value='Institutions'
        >
          Institutions
        </ToggleButton>
      </ToggleButtonGroup>
      <Button
        onClick={handleClickResetDownload}
        className={classes.resetDownloadsButton}
      >
        Reset Downloads
        <RestartAltIcon className='rotateIcon' sx={{ marginLeft: '10px' }} />
      </Button>

      <ResetDownloadsModal
        classes={classes}
        open={isModalOpen}
        onClose={handleCloseModal}
        onResetDownloads={handleResetDownloads}
      />
    </div>
  );
};

export default ManagementHeaderWrapper;
