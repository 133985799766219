import { Box, Typography } from '@mui/material';
import BackgroundIcon from 'assets/images/path7732.svg';
import Avatar from 'react-avatar';
import { PRIMARY_COLOR } from 'styles/colors';
import {
  BottomContainer,
  ExternalContainer,
  TopContainer,
} from 'styles/profile';

import { QueryClient, QueryClientProvider } from 'react-query';

import { UserModel } from 'models';

import { connect } from 'react-redux';

import DownloadsCounter from 'pages/product/DownloadsCounter';
import { project } from 'project';
import { isBuyer } from 'services/authService';

interface OwnProps {
  history: any;
}

interface StateProps {
  user: UserModel;
}

type Props = OwnProps & StateProps;

const queryClient = new QueryClient();

const ProfileWrapper: React.FC<Props> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Profile {...props} />
    </QueryClientProvider>
  );
};

const Profile: React.FC<Props> = ({ user }) => {
  return (
    <Box sx={ExternalContainer}>
      <Box sx={ExternalContainer.background}>
        <img src={BackgroundIcon} alt='background-icon' />
      </Box>
      <Box sx={TopContainer}>
        <Box sx={TopContainer.leftSide}>
          <Box sx={TopContainer.image}>
            <Avatar
              color={PRIMARY_COLOR}
              name={`${user.firstname} ${user.lastname}`}
              round='50%'
              size='100'
            />
          </Box>
          <Box sx={TopContainer.userInformation}>
            <Typography variant='h3'>
              {user?.firstname + ' ' + user?.lastname}
            </Typography>
            <Typography variant='h4'>{'@' + user?.username}</Typography>
          </Box>
        </Box>
        <Box sx={TopContainer.rightSide}>
          <Box sx={TopContainer.filmCounter}>
            {isBuyer() && project !== 'scene' ? <DownloadsCounter /> : <></>}
          </Box>
        </Box>
      </Box>
      <Box sx={BottomContainer}>
        <Box sx={BottomContainer.container}>
          <Box sx={BottomContainer.header}>
            <Box sx={BottomContainer.title}>
              <Typography variant='h4'>Personal information</Typography>
            </Box>
            <Box sx={BottomContainer.icon}></Box>
          </Box>
          <Box sx={BottomContainer.information}>
            <Box sx={BottomContainer.dataRow}>
              <Box sx={BottomContainer.dataRow.leftSide}>
                <Typography>Email</Typography>
              </Box>
              <Box sx={BottomContainer.dataRow.rightSide}>
                <Typography>{user?.email}</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={BottomContainer.footer}>
            {/* <Box sx={BottomContainer.footer.leftSide}>
              <Button variant='contained' disableElevation>
                Change password
              </Button>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ProfileWrapper);
