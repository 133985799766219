import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, IconButton, Modal } from '@mui/material';
import { AuthenticatedLink } from 'components/authenticatedLink/authenticatedLink';
import { modalContractStyle } from 'components/modal/TermsUsageModalStyle';
import React from 'react';
import {
  DownloadContract,
  getContract,
} from 'services/educationalLicenseService';

import { convertHtmlToReact } from '@hedgedoc/html-to-react';
import { withStyles } from '@material-ui/styles';
import Styles from 'pages/licenses/styles/LListStyles';
import { useQuery } from 'react-query';

interface ContractModalProps {
  classes: any;
  open: boolean;
  row: any;
  handleClose: () => void;
}

const ContractModal: React.FC<ContractModalProps> = ({
  classes,
  open,
  row,
  handleClose,
}) => {
  const {
    data: contract,
    isLoading,
    isError,
    error,
  } = useQuery(['licensesContract', row.licenseId], () =>
    getContract(row.licenseId)
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <Box sx={modalContractStyle}>
        <Box className={classes.boxDownloadClose}>
          <AuthenticatedLink
            className={classes.downloadLicenseBtn}
            url={DownloadContract(row.licenseId)}
            filename={`Contract-${row.productTitle.replace(/[ ]+/g, '-')}.pdf`}
          >
            <Button
              className={classes.downloadbtnText}
              startIcon={<DownloadIcon />}
            >
              Download
            </Button>
          </AuthenticatedLink>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box className={classes.boxLicenseTemplate}>
          <div>{convertHtmlToReact(contract)}</div>
        </Box>
      </Box>
    </Modal>
  );
};

export default withStyles(Styles as any)(ContractModal);
