import { Button, InputAdornment, Link, Typography } from '@material-ui/core';
import EmailIcon from '@mui/icons-material/Email';
import LogoScene from 'assets/logo/logo-scene.png';
import LogoImg from 'assets/logo/mfh-logo.png';
import TextInput from 'components/controls/textInput/TextInput';
import React, { useState } from 'react';
import { Link as NavLink } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import Styles from 'styles/auth';

import { QueryClient, QueryClientProvider, useMutation } from 'react-query';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { RecoverPasswordModel } from 'mapfilm-api';
import { recoverPassword } from 'services/authService';

import { project } from 'project';

import { PRIMARY_COLOR, SECONDARY_COLOR } from 'styles/colors';
import Swal from 'sweetalert2';

interface OwnProps {
  classes?: any;
}

const queryClient = new QueryClient();

const ResetPasswordWrapper: React.FC<OwnProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ResetPassword {...props} />
    </QueryClientProvider>
  );
};

const ResetPassword: React.FC<OwnProps> = ({ classes }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
    setError(null);
  };

  const recoverPasswordMutation = useMutation(
    (recoverPasswordModel: RecoverPasswordModel) =>
      recoverPassword(recoverPasswordModel),
    {
      onError: (error: any) => {
        // setError(error.message || 'An error occurred.');
        setError('*This email is not registered.');
      },
      onSuccess: () => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          iconColor: SECONDARY_COLOR,
          text: 'Success! The password reset instructions have been sent to your email.',
          showConfirmButton: false,
        });
        setError(null);
      },
    }
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!emailRegex.test(email)) {
      setError('*Please enter a valid email address.');
      return;
    } else {
      const recoverPasswordModel: RecoverPasswordModel = { email };
      // console.log(recoverPasswordModel);
      recoverPasswordMutation.mutate(recoverPasswordModel);
    }
  };

  return (
    <div className={classes.resetFormContainer}>
      <div className={classes.resetFormBox}>
        <div className={classes.titleContainer}>
          <div className={classes.logoSection}>
            <NavLink to='/'>
              {project === 'scene' ? (
                <img className={classes.logoAuth} src={LogoScene} alt='Logo' />
              ) : (
                <>
                  <img
                    className={classes.logoAuth}
                    src={LogoImg}
                    alt='Project Logo'
                  />
                </>
              )}
            </NavLink>
          </div>
          <Typography
            component='h1'
            variant='h5'
            classes={{ root: classes.registerTitle }}
          >
            <span className={classes.changeColor}>Reset</span> password
          </Typography>
          <p className={classes.aboveSubtitle}>
            Enter the email address associated with your account and we&apos;ll
            send you a link to reset your password
          </p>
        </div>
        <form className='auth-form'>
          <TextInput
            type='text'
            label='Academic Email Address'
            name='username'
            value={email}
            setInputValue={handleChange}
            placeholder='Enter your email address'
            disabled={false}
            required={true}
            autoComplete='email'
            startAdornment={
              <InputAdornment
                classes={{ positionStart: classes.positionStart }}
                position='start'
              >
                <EmailIcon />
              </InputAdornment>
            }
          />
          <div className={classes.termsLinkBtn}>
            <Button
              className={classes.resetPasswordBtn}
              variant='outlined'
              onClick={(e: any) => {
                handleSubmit(e);
              }}
              disabled={email === '' || recoverPasswordMutation.isLoading}
            >
              {recoverPasswordMutation.isLoading ? (
                <Box sx={{ display: 'inline', paddingTop: '8px' }}>
                  <CircularProgress
                    sx={{
                      color: PRIMARY_COLOR,
                      width: '25px !important',
                      height: '25px !important',
                    }}
                  />
                </Box>
              ) : (
                'Continue'
              )}
            </Button>
          </div>
          {error && (
            <Typography color='error' style={{ marginTop: '20px' }}>
              {error}
            </Typography>
          )}

          <div className={classes.signUpResetContainer}>
            <Typography className={classes.authTxt}>
              {"Don't have an account?"}
            </Typography>
            <Link
              classes={{ root: classes.authLink }}
              component={NavLink}
              to='/auth/register'
            >
              Sign up
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withStyles(Styles as any)(ResetPasswordWrapper);
