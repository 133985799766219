// import { withStyles } from '@material-ui/styles';
import React from 'react';

// import combineStyles from 'utils/combineStyles';

// import Styles from 'pages/licenses/styles/LListStyles';
import { project } from 'project';
// import DashStyles from 'styles/dashboard';
import CLlistMFHWrapper from './CLlistMFH';
import CLlistOthersWrapper from './CLlistOthers';

interface Props {
  classes: any;
  history: any;
}

const CLlist: React.FC<Props> = () => {
  return (
    <>{project === 'mfh' ? <CLlistMFHWrapper /> : <CLlistOthersWrapper />}</>
  );
};

// const combinedStyles = combineStyles(DashStyles, Styles);

// export default withStyles(combinedStyles as any)(CLlist);

export default CLlist;
