import { createStyles } from '@material-ui/styles';
import { PRIMARY_COLOR, THIRD_COLOR } from 'styles/colors';

const useStyles = (theme: any) =>
  createStyles({
    /* New Dashbord Layout */
    root: {
      display: 'flex',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 8px',
      height: 72,
    },
    appBar: {
      backgroundColor: PRIMARY_COLOR,
      color: THIRD_COLOR,
      boxShadow: 'none',
      // boxShadow: '0px 4px 10px rgba(240, 240, 240, 0.25)',
    },
    appBarShift: {
      marginLeft: 330,
      // width: `calc(100% - ${330}px)`,
      width: '100%',
    },
    categoryDropdown: {
      /*width: "18%",*/
      width: '9.5%',
      cursor: 'pointer',
      fontSize: 16,
      fontWeight: 500,
      textAlign: 'center',
    },
    expandIcon: {
      fontSize: 24,
      verticalAlign: 'middle',
      marginLeft: 5,
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    logoImg: {
      marginRight: '80px',
      marginLeft: '80px',
    },
    logoImgScene: {
      width: '110px',
    },
    logoImgMfh: {
      width: '60px',
    },
  });

export default useStyles;
