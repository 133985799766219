import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { connect } from 'react-redux';

import { UserModel } from 'models';
import Styles from 'pages/licenses/styles/LListStyles';
import DashStyles from 'styles/dashboard';
import combineStyles from 'utils/combineStyles';

import { Link } from 'react-router-dom';

// import TheatersIcon from '@mui/icons-material/Theaters';

import Loading from 'components/loading/Loading';

import DownloadsCounter from 'pages/product/DownloadsCounter';
import { getDownloadsList } from 'services/productService';

interface OwnProps {
  classes: any;
  history: any;
}

interface StateProps {
  user: UserModel;
}

type Props = OwnProps & StateProps;

const queryClient = new QueryClient();

const DownloadsWrapper: React.FC<Props> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Downloads {...props} />
    </QueryClientProvider>
  );
};

const Downloads: React.FC<Props> = ({ classes }) => {
  const { data: downloadList, isLoading } = useQuery(
    ['downloads'],
    getDownloadsList
  );

  React.useEffect(() => {
    // console.log(downloadList)
  }, [downloadList, isLoading]);

  return (
    <React.Fragment>
      <div className={classes.headerContainer}>
        <Typography className={classes.dashboardTitle} variant='h4'>
          My Downloads
        </Typography>
        <DownloadsCounter />
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Director</TableCell>
                {/* <TableCell>Go to film page</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody className={classes.tableBody}>
              {downloadList && downloadList.length === 0 ? (
                <TableRow>
                  <TableCell className={classes.noLicenses} colSpan={5}>
                    No films downloaded yet.
                  </TableCell>
                </TableRow>
              ) : (
                downloadList.map((row: any) => (
                  <TableRow key={row?.id}>
                    <TableCell className={classes.downloadImageCell}>
                      <img
                        src={row?.product?.posterUrl}
                        alt='film poster'
                        className={classes.downloadImage}
                      />
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      <Link
                        className={classes.downloadLink}
                        to={`/movies/${row?.product?.id}`}
                      >
                        {row?.product?.title}
                      </Link>
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      {row?.product?.director}
                    </TableCell>
                    {/* <TableCell component='th' scope='row'>
                      <Link
                        className={classes.goFilmLink}
                        to={`/movies/${row.product.id}`}
                      >
                        <TheatersIcon />
                      </Link>
                    </TableCell> */}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

const combinedStyles = combineStyles(DashStyles, Styles);

export default connect(mapStateToProps)(
  withStyles(combinedStyles as any)(DownloadsWrapper)
);
