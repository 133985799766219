import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useCallback } from 'react';

import TextArea from 'components/controls/textarea/TextArea';
import TextInput from 'components/controls/textInput/TextInput';
import FieldData from 'components/fielddata/FieldData';
import Genres from 'pages/product/ProductGenres';
import combineStyles from 'utils/combineStyles';
import { removeHTML } from 'utils/utils';

import DashboardStyles from 'styles/dashboard';
import MovieStyles from './PStyles';

import { THIRD_COLOR } from 'styles/colors';

//import MovieIcon from '@material-ui/icons/Movie';
import MovieIcon from '@mui/icons-material/Movie';
import DefaultPoster from 'assets/images/default-img.jpg';
import { LibraryProduct } from 'mapfilm-api';

import { project } from 'project';
import { showField } from 'utils/projectFieldsDefinitions';

interface Props {
  classes: any;
  product: LibraryProduct;
  openVideoPopup: (value: boolean) => void;
  setVideoInfo: (id: string, dialogTitle: string) => void;
}

const ProductInfo: React.FC<Props> = ({
  classes,
  product,
  openVideoPopup,
  setVideoInfo,
}) => {
  const handleVersionSelect = useCallback(
    (version: any) => {
      setVideoInfo(version._id, version.name);
      openVideoPopup(true);
    },
    [setVideoInfo, openVideoPopup]
  );

  if (!product.genres) {
    product.genres = [];
  }

  const title = product.title;

  const year = product.year;
  const duration = product.duration;
  const poster = product.posterUrl ?? DefaultPoster;
  const synopsis = product.synopsis
    ? removeHTML(product.synopsis).replace(/$(\r|\n)(?=.)/gm, ' ')
    : product.synopsis;

  const credits = product.creditRights;

  const suggCredits = credits
    ? `${title} ${year ? `(${year})` : ''} © ${credits}`
    : '';

  return (
    <>
      <Grid container spacing={3}>
        {/* Left Column */}
        <Grid item container sm={4} alignItems='flex-start'>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.moviePosterContainer}>
              <img
                className={classes.moviePoster}
                src={poster}
                alt='Film Poster'
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.movieOptions}>
                {product.versions &&
                  product.versions.map((version: any) => (
                    <Button
                      key={version}
                      className={classes.secondaryBtn}
                      variant='outlined'
                      onClick={() => handleVersionSelect(version)}
                    >
                      <MovieIcon
                        sx={{ color: THIRD_COLOR, marginRight: '10px' }}
                      />
                      <span className={classes.optionDescription}>
                        {project === 'mfh' ? product.title : version.name}
                      </span>
                    </Button>
                  ))}
              </div>
            </Grid>
            {product.language && showField('language') ? (
              <Grid item xs={12}>
                <TextInput
                  type='text'
                  label='Language'
                  pageType='product'
                  placeholder='Enter the film language'
                  value={product.language}
                  disabled={true}
                  required={true}
                />
              </Grid>
            ) : (
              <></>
            )}
            {product.regions &&
              showField('regions') &&
              product.regions.length !== 0 && (
                <Grid item sm={12}>
                  <FieldData label='Regions' description='' />
                  <Genres genres={product.regions} />
                </Grid>
              )}
          </Grid>
        </Grid>

        {/* Right Column */}
        <Grid item container sm={8} alignItems='flex-start'>
          <Grid item container spacing={3}>
            <Grid item sm={6}>
              <TextInput
                type='text'
                label='Title'
                pageType='product'
                placeholder='Enter the film title'
                value={title}
                disabled={true}
                required={true}
              />
            </Grid>

            {product.director ? (
              <Grid item sm={6}>
                <TextInput
                  type='text'
                  label='Director'
                  placeholder='Enter the director name'
                  pageType='product'
                  value={product.director}
                  disabled={true}
                  required={true}
                />
              </Grid>
            ) : (
              <></>
            )}

            {product.owner ? (
              <Grid item sm={product.director ? 4 : 6}>
                <TextInput
                  type='text'
                  label='Rights Owner'
                  pageType='product'
                  value={product.owner}
                  disabled={true}
                  required={true}
                />
              </Grid>
            ) : (
              <></>
            )}

            {year ? (
              <Grid item sm={product.director ? 4 : 6}>
                <TextInput
                  type='number'
                  label='Year'
                  pageType='product'
                  placeholder='Enter the film year'
                  value={year ? year : -1}
                  disabled={true}
                  required={true}
                />
              </Grid>
            ) : (
              <></>
            )}

            {product.contentRating && showField('contentRating') ? (
              <Grid item sm={product.director ? 4 : 6}>
                <TextInput
                  type='text'
                  label='Content rating'
                  pageType='product'
                  placeholder='Select the content rating'
                  value={product.contentRating}
                  disabled={true}
                  required={true}
                />
              </Grid>
            ) : (
              <></>
            )}

            {duration ? (
              <Grid item sm={product.director ? 4 : 6}>
                <TextInput
                  type='text'
                  label='Duration'
                  pageType='product'
                  placeholder='Select the duration'
                  value={duration}
                  disabled={true}
                  required={true}
                />
              </Grid>
            ) : (
              <></>
            )}

            {product.genres && product.genres.length !== 0 && (
              <Grid item sm={12}>
                <FieldData label='Themes' description='' />
                <Genres genres={product.genres ?? []} />
              </Grid>
            )}

            {synopsis ? (
              <Grid item sm={12}>
                <TextArea
                  type='text'
                  label='Synopsis'
                  pageType='product'
                  placeholder='Enter the description of the film'
                  value={synopsis}
                  multiline={true}
                  rows={8}
                  disabled={true}
                  required={true}
                />
              </Grid>
            ) : (
              <></>
            )}

            {showField('credits') && credits && (
              <Grid item sm={12}>
                <TextArea
                  type='text'
                  label='Suggested credit'
                  pageType='product'
                  placeholder='Enter the suggested credit'
                  value={suggCredits}
                  multiline={true}
                  rows={2}
                  disabled={true}
                  required={true}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const combinedStyles = combineStyles(DashboardStyles, MovieStyles);
export default withStyles(combinedStyles)(ProductInfo);
