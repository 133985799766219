import { createStyles } from '@material-ui/styles';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'styles/colors';

const useStyles = createStyles({
  pnfContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  pnfIcon: {
    fontSize: 125,
    color: SECONDARY_COLOR,
    marginTop: '10%',
  },
  pnfTxt: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: PRIMARY_COLOR,
    marginTop: '20px',
  },
});

export default useStyles;
