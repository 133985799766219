import { createStyles } from '@material-ui/styles';
import ResetBackground from 'assets/images/resetBackground.png';

import {
  HOVER_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  THIRD_COLOR,
} from './colors';

const useStyles = createStyles({
  /* Login page styling */
  loginTitle: {
    fontSize: 40,
    fontWeight: 700,
    color: 'black',
    paddingBottom: 60,
  },
  positionEndLogin: {
    marginRight: -12,
    '& svg': {
      width: 18,
      height: 18,
    },
  },
  forgotLink: {
    color: '#7A7A7A',
    fontSize: 16,
    textDecoration: 'underline',
    '&:hover': {
      color: HOVER_COLOR,
    },
    '&:focus': {
      color: HOVER_COLOR,
    },
  },
  loginLinkContainer: {
    display: 'flex',
    fontSize: 16,
  },
  authOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoSection: {
    marginBottom: '54.32px',
    marginTop: '70px',
  },
  logoAuth: {
    width: '150px',
  },

  /* Register page styling */
  registerTitle: {
    fontSize: 40,
    fontWeight: 700,
    color: 'white',
    marginTop: '70px',
  },
  changeColor: {
    color: SECONDARY_COLOR,
  },
  formContainer: {
    paddingBottom: 15,
    '& .form-input': {
      marginBottom: 3,
    },
  },
  positionEndRegister: {
    marginRight: -12,
    '& svg': {
      width: 18,
      height: 18,
    },
  },
  disclaimerContainer: {
    marginTop: -10,
    marginBottom: 8,
  },
  permissionCheckbox: {
    '& .MuiFormControlLabel-label': {
      letterSpacing: '0.2px',
      fontSize: 14,
    },
  },
  permissionsDisclaimer: {
    letterSpacing: '0.2px',
    fontSize: 14,
    color: THIRD_COLOR,
  },
  termsCheckbox: {
    marginTop: 0,
    '& .MuiFormControlLabel-label': {
      letterSpacing: '0.2px',
      fontSize: 14,
      color: THIRD_COLOR,
    },
    '& .MuiIconButton-label': {
      color: SECONDARY_COLOR,
    },
  },
  signUpLinkContainer: {
    display: 'flex',
    fontSize: 16,
    marginTop: 60,
    marginBottom: 60,
  },
  forgotContainer: {
    display: 'flex',
    fontSize: 16,
    marginBottom: 60,
  },
  registerOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '60px',
  },
  toogleButton: {
    backgroundColor: PRIMARY_COLOR,
    border: `1px solid ${THIRD_COLOR}`,
    width: '100%',
    display: 'flex',
    marginBottom: '30px',
  },
  toggleButtonHalf: {
    flex: '1',
  },
  selectedToggle: {
    backgroundColor: `${SECONDARY_COLOR} !important`,
    color: `${PRIMARY_COLOR} !important`,
    fontWeight: 'bold',
  },
  notSelectedToggle: {
    color: `${THIRD_COLOR} !important`,
    fontWeight: 'bold',
  },
  titleContainer: {
    paddingBottom: '40px',
  },
  aboveSubtitle: {
    color: THIRD_COLOR,
    fontSize: '18px',
    fontWeight: 400,
    letterSpacing: '0.02em',
    paddingTop: '8px',
    paddingBottom: '15px',
  },
  signUpResetContainer: {
    display: 'flex',
    fontSize: 16,
    marginTop: 60,
    marginBottom: 60,
    justifyContent: 'center',
  },
  /* Reset Password */
  resetFormContainer: {
    backgroundColor: 'white',
    backgroundImage: `url(${ResetBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    minHeight: '100vh',
    width: '100%',
  },
  projectLogo: {
    width: '100%',
  },
  resetFormBox: {
    width: 520,
    paddingLeft: '100px',
  },
  resetPasswordBtn: {
    width: '100%',
    height: 48,
    backgroundColor: SECONDARY_COLOR,
    color: 'black',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: 16,
    letterSpacing: '0.02em',
    '&:hover': {
      backgroundColor: HOVER_COLOR,
    },
  },
  /* Common styling */
  authContainer: {
    position: 'relative',
    display: 'flex',
    minHeight: '100vh',
  },
  authFormContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    paddingBottom: '20px',
    position: 'relative',
    flexDirection: 'column',
    flex: '1',
    width: '100%',
  },
  authFormBox: {
    width: 520,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4vw',
    marginBottom: '4vw',
  },
  positionStart: {
    marginRight: 12,
  },
  iconButton: {
    padding: 8,
  },
  authLink: {
    color: THIRD_COLOR,
    fontWeight: 700,
    textDecoration: 'underline',
    '&:hover': {
      color: HOVER_COLOR,
    },
    '&:focus': {
      color: HOVER_COLOR,
    },
  },
  authTxt: {
    color: 'white',
    marginRight: 5,
  },
  authError: {
    fontSize: '1rem',
    paddingBottom: 20,
    marginTop: -35,
  },
  authFeedback: {
    fontSize: '1rem',
    paddingBottom: 20,
  },
  submit: {
    width: 208,
    height: 48,
    backgroundColor: `${SECONDARY_COLOR} !important`,
    color: PRIMARY_COLOR,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: 16,
    letterSpacing: '0.02em',
    '& .Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    '&:hover': {
      backgroundColor: HOVER_COLOR,
    },
  },
  inputTextfield: {
    width: '100%',
    '& label.Mui-focused': {
      color: 'grey',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'grey',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'grey',
      },
    },
  },
  inputTextfieldError: {
    width: '100%',
    '& label.Mui-focused': {
      color: 'red',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'red',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
  /* Terms and conditions/privacy policy page styling */
  termsTitle: {
    fontSize: 40,
    fontWeight: 700,
    color: THIRD_COLOR,
    paddingBottom: 20,
  },
  lastUpdate: {
    color: '#7A7A7A',
    paddingBottom: 10,
  },
  termsSubtitle: {
    fontSize: 30,
    fontWeight: 600,
    color: SECONDARY_COLOR,
    paddingTop: 5,
    paddingBottom: 15,
  },
  privacySubtitle: {
    fontSize: 25,
    fontWeight: 600,
    color: SECONDARY_COLOR,
    paddingTop: 5,
    paddingBottom: 15,
  },
  termsBox: {
    marginTop: 75,
    marginBottom: 75,
    maxWidth: 650,
    textAlign: 'justify',
  },
  termsList: {
    '& > li': {
      marginTop: 5,
      fontSize: '1rem',
      color: THIRD_COLOR,
    },
  },
  termsTxt: {
    color: THIRD_COLOR,
    marginBottom: 18,
  },
  highlightTxt: {
    color: SECONDARY_COLOR,
    fontWeight: 'bold',
  },
  termsContact: {
    color: THIRD_COLOR,
    marginBottom: 5,
  },
  termsLinkBtn: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  termsBtn: {
    marginTop: 30,
    width: 208,
    height: 48,
    backgroundColor: SECONDARY_COLOR,
    color: PRIMARY_COLOR,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: 16,
    letterSpacing: '0.02em',
    '&:hover': {
      backgroundColor: HOVER_COLOR,
    },
  },

  /* Verification page styling */
  verificationContainer: {
    paddingTop: 15,
    paddingBottom: 40,
  },
  verificationTitle: {
    fontSize: 35,
    fontWeight: 700,
    color: SECONDARY_COLOR,
  },
  verificationSubtitle: {
    color: THIRD_COLOR,
    fontSize: 18,
    fontWeight: 400,
    letterSpacing: '0.02em',
    paddingTop: 8,
  },
  redirectLink: {
    '&:hover': {
      textDecoration: 'none',
    },
  },

  /* Profile page */
  avatar: {
    '@media (max-width: 1600px)': {
      width: '175px !important',
      height: '175px !important',
    },
    '@media (max-width: 1350px)': {
      width: '150px !important',
      height: '150px !important',
    },
  },
  formErrorContainer: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  buttonsContainer: {
    marginTop: 10,
    marginBottom: 50,
  },
});

export default useStyles;
