import { combineReducers } from 'redux';
import customLicenseReducer from './customLicenseReducer';
import licenseTemplateReducer from './licenseTemplateReducer';
import licenseWindowReducer from './licenseWindowReducer';
import userReducer from './userReducer';

const reducer = combineReducers({
  user: userReducer,
  licenseTemplate: licenseTemplateReducer,
  customLicense: customLicenseReducer,
  licenseWindow: licenseWindowReducer,

  /*cart: cartReducer,
    cartDrawer: cartDrawerReducer,
    purchase: purchaseReducer,
    homePage: homePageReducer*/
});

export default reducer;
