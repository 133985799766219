import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import lineSplit from 'assets/images/lineSplit.svg';
import Loading from 'components/loading/Loading';
import {
  EditProductWithPoster,
  ProductVersionForm,
} from 'models/ProductsModel';
import AddMedia from 'pages/products/addFilms/AddMedia';
import FilmDetails from 'pages/products/addFilms/FilmDetails';
import React, { useEffect, useState } from 'react';
import { isBuyer } from 'services/authService';
import { getProduct, updateProduct } from 'services/productService';
import { PRIMARY_COLOR, THIRD_COLOR } from 'styles/colors';
import Swal from 'sweetalert2';

import Styles from 'styles/dashboard';

import { QueryClient, QueryClientProvider, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

interface OwnProps {
  classes: any;
  history: any;
  match: any;
}

interface State {
  product: EditProductWithPoster;
  imageURL: string;
  edited: boolean;
  addMedia: ProductVersionForm[];
  formErrorMessage: string;
}

const queryClient = new QueryClient();

const EditProductPageWrapper: React.FC<OwnProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <EditProductPage {...props} />
    </QueryClientProvider>
  );
};

const EditProductPage: React.FC<OwnProps> = ({ classes, match }) => {
  const history = useHistory();
  const [addMedia, setAddMedia] = useState<ProductVersionForm[]>([]);
  const [product, setProduct] = useState<EditProductWithPoster>({
    id: '',
    title: '',
    genres: [] as string[],
    regions: [] as string[],
    name: '',
  });

  const transformProperties = (genres: any): string[] => {
    return genres.map((genre: any) =>
      typeof genre === 'string' ? genre : genre
    );
  };

  const fetchProduct = async (
    productId: string
  ): Promise<EditProductWithPoster> => {
    // const product = await getProduct(productId, isBuyer());
    // return product;
    const product = await getProduct(productId, isBuyer());
    return {
      ...product,
      genres: product.genres ? transformProperties(product.genres) : undefined,
      regions: product.regions
        ? transformProperties(product.regions)
        : undefined,
    };
  };

  const getProductMutation = useMutation(
    async (productId: string) => {
      const product = await fetchProduct(productId);
      return product;
    },
    {
      onSuccess: (data: EditProductWithPoster) => {
        setProduct(data);

        if (data.versions !== undefined && data.versions?.length !== 0) {
          const media = data.versions.map((version: any) => ({
            version: version.name,
            video: version.renditions[0].url,
            vizziID: version._id,
            status: version.uploaderJobStatus,
          }));

          setAddMedia(media);
        }
      },
      onError: (error: any) => {
        console.error('Error fetching product:', error);
      },
    }
  );

  useEffect(() => {
    const productId = match.params.id;
    getProductMutation.mutate(productId);
  }, [match.params.id]);

  const handleFilmStatusQuery = (
    status: { index: number; status: string; id: string }[]
  ) => {
    setAddMedia((prevMedia) =>
      prevMedia.map((media, index) => {
        const foundStatus = status.find((s) => s.index === index);
        if (foundStatus) {
          return {
            ...media,
            status: foundStatus.status,
            vizziID: foundStatus.id,
          };
        }
        return media;
      })
    );
  };

  const handleFilmVersionAdd = (index: number, uuid: string) => {
    setAddMedia((prevMedia) =>
      prevMedia.map((media, idx) => {
        if (idx === index) {
          return {
            ...media,
            uuid,
            status: 'UPLOADING',
          };
        }
        return media;
      })
    );
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const handleDropPoster = (acceptedFiles: File) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      poster: acceptedFiles,
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;
    if (value === '') {
      // If the value is empty, remove the key from the product state
      setProduct((prevProduct) => {
        const updatedProduct = { ...prevProduct };
        delete updatedProduct[name as keyof typeof updatedProduct];
        return updatedProduct;
      });
    } else if (name === 'year') {
      if (!isNaN(Number(value)) && !value.includes('-') && value.length <= 4) {
        // If the value is a valid year, update the 'year' property
        setProduct((prevProduct) => ({
          ...prevProduct,
          year: parseInt(value),
        }));
      }
    } else if (name === 'duration') {
      if (!isNaN(Number(value)) && !value.includes('-')) {
        // If the value is a valid number, update the 'duration' property
        setProduct((prevProduct) => ({
          ...prevProduct,
          duration: parseInt(value),
        }));
      }
    } else if (type === 'checkbox') {
      setProduct((prevProduct) => ({
        ...prevProduct,
        online: checked,
      }));
    } else {
      // For other fields, update the product state with the new value
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleLangChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: any
  ) => {
    const { name, value } = event.target;
    if (newValue === '') {
      setProduct((prevProduct) => {
        const updatedProduct = { ...prevProduct };
        delete updatedProduct.language;
        return updatedProduct;
      });
      return;
    }
    if (newValue !== undefined) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        language: newValue.name,
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleRatingChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: any
  ) => {
    const { name, value } = event.target;
    if (newValue === '') {
      setProduct((prevProduct) => {
        const updatedProduct = { ...prevProduct };
        delete updatedProduct.contentRating;
        return updatedProduct;
      });
      return;
    }
    if (newValue !== undefined) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        contentRating: newValue.code,
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleGenresChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
    reason: string
  ) => {
    const { name, value } = event.target;
    if (reason === 'select-option' || reason === 'remove-option') {
      const selectedGenres = newValue;
      setProduct((prevProduct) => ({
        ...prevProduct,
        genres: selectedGenres,
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleRegionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
    reason: string
  ) => {
    const { name, value } = event.target;
    if (reason === 'select-option' || reason === 'remove-option') {
      const selectedRegions = newValue;
      setProduct((prevProduct) => ({
        ...prevProduct,
        regions: selectedRegions,
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleCreateNewVersion = () => {
    setAddMedia((prevMedia) => [...prevMedia, { version: '', video: null }]);
  };

  const handleRemoveForm = (index: number) => {
    setAddMedia((prevMedia) => prevMedia.filter((_, i) => i !== index));
  };

  const handleInputChange = (
    index: number,
    field: keyof ProductVersionForm,
    value: string
  ) => {
    setAddMedia((prevMedia) =>
      prevMedia.map((media, idx) => {
        if (idx === index) {
          return {
            ...media,
            [field]: value,
          };
        }
        return media;
      })
    );
  };

  const handleInputDropChange = (
    index: number,
    field: keyof ProductVersionForm,
    acceptedFiles: File[]
  ) => {
    setAddMedia((prevMedia) =>
      prevMedia.map((media, idx) => {
        if (idx === index) {
          return {
            ...media,
            [field]: acceptedFiles,
          };
        }
        return media;
      })
    );
  };

  const mutationSubmit = useMutation(
    async (data: {
      product: EditProductWithPoster;
      mediaList: ProductVersionForm[];
    }) => {
      const { poster, owner, id, ...productVersionForm } = data.product;

      const vizziIDList = data.mediaList
        .filter((media) => media.vizziID !== undefined)
        .map((media) => media.vizziID) as string[];
      productVersionForm.versions = vizziIDList;

      return updateProduct(productVersionForm, poster, id as string);
    },
    {
      onSuccess: () => {
        window.history.back();
      },
      onError: (error: any) => {
        Swal.fire({
          icon: 'error',
          iconColor: PRIMARY_COLOR,
          text: error.message,
          showConfirmButton: false,
        });
      },
    }
  );

  return (
    <React.Fragment>
      <div>
        <Grid container className={classes.customGrid}>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            style={{ justifyContent: 'space-between' }}
          >
            <Typography
              className={classes.dashboardTitle}
              variant='h4'
              sx={{ fontWeight: 'bold' }}
            >
              Edit film
            </Typography>
            <Grid item className={classes.productBtnContainer}>
              <button
                className={`${classes.secondaryBtn} ${classes.btnSpaces}`}
                onClick={handleGoBack}
              >
                <span className={classes.optionDescription}>
                  Back to my film
                </span>
              </button>
              <button
                className={classes.dashboardButton}
                type='submit'
                onClick={() =>
                  mutationSubmit.mutate({
                    product: product,
                    mediaList: addMedia,
                  })
                }
                disabled={
                  !addMedia.every((item) => {
                    return item.vizziID !== undefined;
                  }) || product.title === ''
                }
              >
                {mutationSubmit.isLoading ? (
                  <Box sx={{ display: 'inline', paddingTop: '8px' }}>
                    <CircularProgress
                      sx={{
                        color: THIRD_COLOR,
                        width: '25px !important',
                        height: '25px !important',
                      }}
                    />
                  </Box>
                ) : (
                  'Save'
                )}
              </button>
            </Grid>
          </Grid>
          <img src={lineSplit} className={classes.lineTop} />

          {getProductMutation.isLoading ? (
            <Loading />
          ) : (
            <>
              <FilmDetails
                product={product}
                handleDrop={handleDropPoster}
                handleChange={handleChange}
                handleLangChange={handleLangChange}
                handleRatingChange={handleRatingChange}
                handleGenresChange={handleGenresChange}
                handleRegionChange={handleRegionChange}
                edit={true}
              />
              <AddMedia
                product={product}
                classes={classes}
                productMedia={addMedia}
                handleAddForm={handleCreateNewVersion}
                handleRemoveForm={handleRemoveForm}
                handleInputChange={handleInputChange}
                handleDropChange={handleInputDropChange}
                handleFilmStatusQuery={handleFilmStatusQuery}
                handleFilmVersionAdd={handleFilmVersionAdd}
              />
            </>
          )}

          <img src={lineSplit} className={classes.lineBottom} />
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default withStyles(Styles as any)(EditProductPageWrapper);
