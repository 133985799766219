import { withStyles } from '@material-ui/styles';
import React from 'react';

import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import PageNotFoundStyles from './PageNotFoundStyles';

interface Props {
  classes?: any;
  history: any;
  location: any;
}

const PageNotFound: React.FC<Props> = ({ classes }) => {
  return (
    <div className={classes.pnfContent}>
      <InfoRoundedIcon className={classes.pnfIcon} />

      <span className={classes.pnfTxt}>404 PAGE NOT FOUND</span>
    </div>
  );
};

export default withStyles(PageNotFoundStyles)(PageNotFound);
