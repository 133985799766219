import { createStyles } from '@material-ui/styles';

import {
  FOURTH_COLOR,
  HOVER_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  THIRD_COLOR,
} from 'styles/colors';

const useStyles = createStyles({
  tableContainer: {
    borderRadius: 10,
    boxShadow: 'none',
    border: '1px solid #D7D7D7',
  },
  tableHead: {
    '& .MuiTableCell-head': {
      color: SECONDARY_COLOR,
      fontSize: 16,
      fontWeight: 'bold',
    },
    '& .MuiTableRow-root > *:not(:last-child)': {
      borderRight: '1px solid #D7D7D7',
    },
  },
  tableBody: {
    '& .MuiTableCell-body': {
      color: PRIMARY_COLOR,
      fontSize: 16,
      padding: 20,
    },
    '& .MuiTableRow-root > *:not(:last-child)': {
      borderRight: '1px solid #D7D7D7',
    },
  },
  downloadLink: {
    color: SECONDARY_COLOR,
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      color: FOURTH_COLOR,
    },
    '&:focus': {
      textDecoration: 'none',
      color: FOURTH_COLOR,
    },
  },
  downloadIcon: {
    fill: SECONDARY_COLOR,
    marginRight: 5,
  },
  noLicenses: {
    textAlign: 'center',
  },
  licenseState: {
    color: 'rgb(122, 122, 122, 0.8)',
    fontWeight: 500,
  },

  /*********SignLicense.tsx */
  checkboxStyle: {
    color: `${SECONDARY_COLOR} !important`,
    '& .Mui-checked': { color: `${SECONDARY_COLOR} !important` },
  },

  buttonStyle: {
    backgroundColor: `${SECONDARY_COLOR} !important`,
    '&:hover': {
      backgroundColor: `${SECONDARY_COLOR} !important`,
    },
  },

  buttonStyleContracts: {
    color: `${SECONDARY_COLOR} !important`,
    borderColor: `${SECONDARY_COLOR} !important`,
  },

  descriptionText: {
    paddingBottom: '30px',
  },

  htmlViewerStyle: {
    width: '100%',
    marginTop: '16px',
    flex: 1,
    height: '600px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '5px',
    backgroundColor: 'white',
    overflowY: 'scroll',
    padding: '0px 0px 16px 16px',
  },

  boxTermsConditions: {
    width: '100%',
    marginTop: '16px',
    flex: 1,
    height: 'auto',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '5px',
    backgroundColor: 'white',
    padding: '16px',
  },

  downloadLicenseBtn: {
    minWidth: 160,
    height: 40,
    paddingRight: 20,
    paddingLeft: 20,
    backgroundColor: SECONDARY_COLOR,
    color: `${THIRD_COLOR} !important`,
    border: `2px solid ${SECONDARY_COLOR}`,
    borderRadius: 5,
    textTransform: 'capitalize',
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: '0.02em',
    marginRight: '50px',
    '&:hover': {
      color: THIRD_COLOR,
      backgroundColor: HOVER_COLOR,
    },
  },
  downloadbtnText: {
    color: `${THIRD_COLOR} !important`,
  },

  boxDownloadClose: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: '#F6F4F2',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  boxLicenseTemplate: {
    marginTop: '16px',
    maxHeight: '80vh',
    overflowY: 'auto',
    padding: '16px',
  },
});

export default useStyles;
