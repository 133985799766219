import { withStyles } from '@material-ui/styles';
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { Link as NavLink } from 'react-router-dom';

import { AuthenticatedLink } from 'components/authenticatedLink/authenticatedLink';
import Loading from 'components/loading/Loading';
import { isBuyer, isSeller } from 'services/authService';
import {
  DownloadContract,
  getSignedLicenses,
} from 'services/educationalLicenseService';
import combineStyles from 'utils/combineStyles';

import { ReactComponent as DownloadIcon } from 'assets/images/download-icon.svg';
import Styles from 'pages/licenses/styles/LListStyles';
import DashStyles from 'styles/dashboard';

interface Props {
  classes: any;
}

const queryClient = new QueryClient();

const CLlistOthersWrapper: React.FC<Props> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <CLlistOthers {...props} />
    </QueryClientProvider>
  );
};

const CLlistOthers: React.FC<Props> = ({ classes }) => {
  const { data: licenses, isLoading } = useQuery(
    'signedLicenses',
    getSignedLicenses,
    {
      onError: (error) => {
        console.error('Error fetching signed licenses:', error);
      },
    }
  );

  return (
    <React.Fragment>
      <div className={classes.headerContainer}>
        <Typography
          className={classes.dashboardTitle}
          variant='h4'
          sx={{ fontWeight: '700 !important' }}
        >
          My Signed Licenses
        </Typography>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>Film Title</TableCell>
                {isBuyer() && <TableCell>Licensor</TableCell>}
                {isSeller() && <TableCell>Licensee</TableCell>}
                {isBuyer() && <TableCell>Creation Date</TableCell>}
                {isSeller() && <TableCell>Start Date</TableCell>}
                <TableCell>Contract</TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={classes.tableBody}>
              {licenses?.length === 0 ? (
                <TableRow>
                  <TableCell className={classes.noLicenses} colSpan={5}>
                    No entries were found.
                  </TableCell>
                </TableRow>
              ) : (
                licenses?.map((row: any) => (
                  <TableRow key={row.id}>
                    <TableCell component='th' scope='row'>
                      <Link
                        className={classes.downloadLink}
                        component={NavLink}
                        to={`/movies/${row.TitleID}`}
                      >
                        {row.FilmTitle}
                      </Link>
                    </TableCell>

                    {isBuyer() && <TableCell>{row.Licensor}</TableCell>}
                    {isSeller() && <TableCell>{row.Licensee}</TableCell>}
                    {isBuyer() && (
                      <TableCell>
                        {moment(new Date(row.CreationDate)).format(
                          'DD-MMM-YYYY'
                        )}
                      </TableCell>
                    )}
                    {isSeller() && (
                      <TableCell>
                        {moment(row.StartDate, 'DD-MMM-YYYY').format(
                          'DD-MMM-YYYY'
                        )}
                      </TableCell>
                    )}

                    <TableCell>
                      <AuthenticatedLink
                        className={classes.downloadLink}
                        url={DownloadContract(row.LicenseID)}
                        filename={`Contract-${row.FilmTitle.replace(/[ ]+/g, '-')}.pdf`}
                      >
                        <DownloadIcon className={classes.downloadIcon} />
                        Download
                      </AuthenticatedLink>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </React.Fragment>
  );
};

const combinedStyles = combineStyles(DashStyles, Styles);

export default withStyles(combinedStyles as any)(CLlistOthersWrapper);
