import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {
  buttonStyle,
  checkboxStyle,
  modalStyle,
  textFieldStyle,
} from './TermsUsageModalStyle';

interface TermsUsageModalProps {
  open: boolean;
  handleClose: () => void;
}

const TermsUsageModal: React.FC<TermsUsageModalProps> = ({
  open,
  handleClose,
}) => {
  const [agree, setAgree] = useState(false);

  const handleModalClose = (event: React.SyntheticEvent, reason: string) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  };

  const handleAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgree(event.target.checked);
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <Box sx={modalStyle}>
        <Typography id='modal-title' variant='h6' component='h2'>
          Terms and Conditions of Usage
        </Typography>
        <Box id='modal-description' sx={textFieldStyle}>
          <Typography paragraph>
            Before downloading and reusing content from this site, please agree
            to the following terms and conditions under which our project
            partners have granted access. These summarize the key points of the
            license agreements signed by your institution.
          </Typography>
          <Typography paragraph>
            By tick the box below and signing this agreement, you agree to abide
            by these terms and conditions when using the Archive Material in
            your film.
          </Typography>
          <ul>
            <li>
              <Typography>
                I agree to only use extracts of up to two minutes’ duration per
                source in my film.
              </Typography>
            </li>
            <li>
              <Typography>
                I will not use the Archive Material in a way that is derogatory
                to any private persons appearing or depicted therein (for
                example, in home movie footage).
              </Typography>
            </li>
            <li>
              <Typography>
                I will appropriately acknowledge the archival sources in my
                film, using the suggested credit listed on the Make Film History
                platform and website.
              </Typography>
            </li>
            <li>
              <Typography>
                I will not copy, distribute, sell, loan, hire or use the Archive
                Material for any commercial purposes, or otherwise transfer the
                material to any third party at any time.
              </Typography>
            </li>
            <li>
              <Typography>
                I will not upload or otherwise make available the Archive
                Material on the world wide web (including social media).
              </Typography>
            </li>
            <li>
              <Typography>
                While the participating archives allow screenings at non-profit
                film festivals and one-off public events, I will seek permission
                from the relevant archive before screening the film commercially
                in any way.
              </Typography>
            </li>
          </ul>
          <Typography paragraph>
            (see{' '}
            <a
              href='https://www.archivesforeducation.com/faq'
              target='_blank'
              rel='noreferrer'
            >
              FAQ
            </a>
            )
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agree}
                sx={checkboxStyle}
                onChange={handleAgreeChange}
              />
            }
            label='I agree with the terms and conditions of usage'
          />
        </Box>
        <Box sx={{ mt: 2, textAlign: 'right' }}>
          <Button
            variant='contained'
            sx={buttonStyle}
            onClick={handleClose}
            disabled={!agree}
          >
            Agree and Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TermsUsageModal;
